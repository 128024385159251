/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { phone, phoneTo } from 'shared/config';
import { Button, Captcha, PhoneInput, Popup, SuccessPopup } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import styles from './Designer.module.scss';
import { useDesignerForm } from '../model';

interface FormData {
  phone: string;
  response: string;
}

interface DesignerFormProps {
  isPopupVisible: boolean;
  setPopupVisible: Dispatch<SetStateAction<boolean>>;
}
export const DesignerForm = ({ isPopupVisible, setPopupVisible }: DesignerFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    resetField,
    control,
  } = useForm<FormData>();
  const captchaRef = useRef<Reaptcha>(null);
  const [captcha, setCaptcha] = useState(nanoid());
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    onSubmit,
    setSuccessIsPopupVisible,
    isSuccessPopupVisible,
  } = useDesignerForm({
    setPopupVisible,
    captchaRef,
    setSubmitDisabled,
    resetField,
    setCaptcha,
  });
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <>
      <Popup
        isPopupVisible={isPopupVisible}
        setPopupVisible={setPopupVisible}
        size="lg"
      >
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form} id="designer-form">
          <h2 className={styles.title}>Форма обратной связи</h2>
          <div className={styles.form_subtitle}>
            <span>Позвоните нам по номеру </span>
            <Link to={phoneTo}>{phone}</Link>
          </div>
          <PhoneInput
            className={styles.input}
            id="phone"
            label="Или оставьте свой номер телефона"
            position="center"
            hasError={errors.phone != null}
            {...register('phone', { required: true, validate: validateContact })}
          />
          <Button
            type="submit"
            className={styles.submit}
            disabled={submitDisabled}
          >
            Оставить заявку
          </Button>
          <small className={styles.error}>
            {errors.phone ? errorMessage[errors.phone.type] : undefined}
          </small>
          <Captcha
            ref={captchaRef}
            key={captcha}
            name="response"
            control={control}
            size="invisible"
            onVerify={() => queueMicrotask(handleSubmit(onSubmit))}
          />
        </form>
      </Popup>
      <SuccessPopup
        isSuccessPopupVisible={isSuccessPopupVisible}
        setPopupVisible={() => {
          setPopupVisible?.(false);
          setSuccessIsPopupVisible(false);
        }}
      />
    </>
  );
};
