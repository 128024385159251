/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { Paths } from 'shared/config';
import { ArrowBackIcon, LoadingHOC } from 'shared/ui';
import { CatalogItem } from 'entities/catalog';
import { useParams } from 'react-router';
import { useMemo } from 'react';
import { useCatalogQuery } from 'shared/api/catalog';
import { Subproduct } from 'shared/api';
import styles from './CatalogList.module.scss';

export const CatalogList = () => {
  const { path } = useParams();
  const input = { category_slug: path };
  const query = useCatalogQuery({ input });
  const catalog = useMemo(() => query.currentData?.Catalog, [query.currentData]);
  return (
    <section className={styles.root}>
      <div className={styles.header}>
        <div className={styles.back}>
          <Link to={Paths.Catalog} className={styles.link}>
            <ArrowBackIcon className={styles.icon} />
          </Link>
          <h1 className={styles.title}>Выберите стиль</h1>
        </div>
        <p className={styles.subtitle}>
          После выбора стиля вы сможете настроить
          <br />
          цвет и другие параметры стола
        </p>
      </div>
      <LoadingHOC isLoading={query?.isLoading} className={styles.loader}>
        <div className={styles.wrapper}>
          {
            catalog?.products?.data?.map(
              (item: Subproduct) => <CatalogItem key={item.id} {...item} />,
            )
          }
        </div>
      </LoadingHOC>
    </section>
  );
};
