import { useRef, useCallback, useMemo, useEffect, type MouseEvent } from 'react';
import type { MainMenuItem as IMainMenuItem, LinePosition } from '../model/types';
import { MainMenuItem } from './MainMenuItem';
import styles from './MainMenu.module.scss';
import { getCoords, useActiveItemByLocation } from '../lib';

interface MainMenuProps {
  items: IMainMenuItem[];
}

type ItemPositions = Map<string, LinePosition>;

export const MainMenu = ({ items }: MainMenuProps) => {
  const navRef = useRef<HTMLElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const { activeItem, setActiveItem } = useActiveItemByLocation(items);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const itemPositions = useMemo<ItemPositions>(() => new Map(), [items]);
  // imperatively control the line
  const setLineShown = useCallback((show: boolean) => {
    if (lineRef.current) {
      lineRef.current.classList[show ? 'add' : 'remove'](styles.lineShown);
    }
  }, [lineRef]);
  const enableLineAnimations = useCallback((enable: boolean) => {
    if (lineRef.current) {
      lineRef.current.classList[enable ? 'remove' : 'add'](styles.lineNoAnimations);
    }
  }, [lineRef]);
  const setLinePosition = useCallback(({ left, width }: LinePosition) => {
    if (lineRef.current) {
      const lineNode = lineRef.current;
      lineNode.style.left = `${left}px`;
      lineNode.style.width = `${width}px`;
      if (!lineNode.classList.contains(styles.lineShown)) {
        // add shown class after timeout so that the first show would be immediate
        setTimeout(() => setLineShown(true), 5);
      }
    }
  }, [lineRef, setLineShown]);

  const createOnPositionChange = (item: IMainMenuItem) => (position: LinePosition) => {
    const currentPositon = itemPositions.get(item.text);
    if (currentPositon?.left !== position.left || currentPositon?.width !== position.width) {
      itemPositions.set(item.text, position);
      if (item.text === activeItem?.text) {
        enableLineAnimations(false);
        setLinePosition(position);
        setTimeout(() => enableLineAnimations(true), 5);
      }
    }
  };

  // handle mouse leave on the menu
  const onMouseLeave = (e: MouseEvent) => {
    if (navRef.current) {
      const { top, left } = getCoords(navRef.current);
      const isOutsideOfMenu = Math.floor(top + navRef.current.clientHeight) <= e.pageY // down
        || Math.ceil(top) >= e.pageY // up
        || Math.ceil(left) >= e.pageX // left
        || Math.floor(left + navRef.current.clientWidth) <= e.pageX; // right
      if (isOutsideOfMenu) {
        // hide line
        if (!activeItem || !itemPositions.has(activeItem.text)) {
          setLineShown(false);
          // move line to active item
        } else {
          setLinePosition(itemPositions.get(activeItem.text)!);
        }
      }
    }
  };

  useEffect(() => {
    if (!activeItem) {
      setLineShown(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  return (
    <nav ref={navRef} className={styles.root} onMouseLeave={onMouseLeave}>
      <ul className={styles.list}>
        {items.map((item) => (
          <MainMenuItem
            key={item.text}
            item={item}
            onPositionChange={createOnPositionChange(item)}
            setLinePosition={setLinePosition}
            setActiveItem={setActiveItem}
          />
        ))}
      </ul>
      <div ref={lineRef} className={styles.line} />
    </nav>
  );
};
