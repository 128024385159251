import { Popup, SuccessPopup } from 'shared/ui';
import { CallbackForm } from 'features/callback';
import { Dispatch, SetStateAction, useState } from 'react';

interface ProductOneClickPurchaseModalProps {
  isPopupVisible: boolean;
  setPopupVisible: Dispatch<SetStateAction<boolean>>
}
export const ProductOneClickPurchaseModal = (
  { isPopupVisible, setPopupVisible }: ProductOneClickPurchaseModalProps,
) => {
  const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
  return (
    <>
      <Popup
        isPopupVisible={isPopupVisible}
        setPopupVisible={setPopupVisible}
        size="xl"
      >
        <CallbackForm
          title="Заказать свой дизайн"
          headerContent
          type="Есть особые пожелания"
          setPopupVisible={setPopupVisible}
          setEnhancedSuccessPopup={setIsSuccessPopupVisible}
          withProductLink
        />
      </Popup>
      <SuccessPopup
        isSuccessPopupVisible={isSuccessPopupVisible}
        setPopupVisible={() => {
          setPopupVisible?.(false);
          setIsSuccessPopupVisible(false);
        }}
      />
    </>
  );
};
