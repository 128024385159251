/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type CartIncreaseAmountMutationVariables = Types.Exact<{
  input: Types.CartProductInput;
}>;


export type CartIncreaseAmountMutation = { __typename?: 'Mutation', CartIncreaseAmount?: { __typename?: 'CartFront', productsCount: number } };


export const CartIncreaseAmountDocument = `
    mutation CartIncreaseAmount($input: CartProductInput!) {
  CartIncreaseAmount(input: $input) {
    productsCount: products_count
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CartIncreaseAmount: build.mutation<CartIncreaseAmountMutation, CartIncreaseAmountMutationVariables>({
      query: (variables) => ({ document: CartIncreaseAmountDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCartIncreaseAmountMutation } = injectedRtkApi;

