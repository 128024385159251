import { DownloadIcon } from 'shared/ui';
import { Link } from 'react-router-dom';
import { ImageData } from 'shared/api';
import styles from './CatalogsItem.module.scss';

interface CatalogItemProps {
  item: ImageData;
}
export const CatalogsItem = ({ item }: CatalogItemProps) => (
  <Link to={item.path ?? ''} target="_blank">
    <div className={styles.root}>
      <span className={styles.text}>{item.name}</span>
      <DownloadIcon className={styles.icon} />
    </div>
  </Link>
);
