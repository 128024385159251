export const policy = {
  title: 'Политика конфиденциальности',
  content: '<div>\n'
    + '    <h4>Настоящая Политика конфиденциальности персональных данных(далее – Политика конфиденциальности) действует в отношении всей информации, которую ИП Изегов Т.С., расположенный на доменном имени тиммебель.онлайн, может получить о Пользователе во время использования сайта.</h4>\n'
    + '\n'
    + ' <div>'
    + '    <span>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</span>\n'
    + '    <p>1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>\n'
    + '    <p>1.1.1. «Администрация сайта» – уполномоченные сотрудники, управляющие сайтом, действующие от имени ИП Изегов Т.С., которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>\n'
    + '    <p>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>\n'
    + '    <p>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>\n'
    + '    <p>«Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>\n'
    + '    <p>1.1.5. «Пользователь сайта» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</p>\n'
    + '    <p>1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>\n'
    + '    <p>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>\n'
    + ' </div>\n '
    + '\n'
    + '<div>'
    + '    <span>2. ОБЩИЕ ПОЛОЖЕНИЯ</span>\n'
    + '    <p>2.1. Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>\n'
    + '    <p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.</p>\n'
    + '    <p>2.3. Настоящая Политика конфиденциальности применяется только к сайту тиммебель.онлайн. Сайт не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте.</p>\n'
    + '    <p>2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.</p>\n'
    + '\n'
    + '</div>\n '
    + '<div>'
    + '    <span>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</span>\n'
    + '    <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте.</p>\n'
    + '    <p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте тиммебель.онлайн в разделе обратной связи включают в себя следующую информацию:</p>\n'
    + '    <p>3.2.1. фамилию, имя, отчество Пользователя;</p>\n'
    + '    <p>3.2.2. контактный телефон Пользователя;</p>\n'
    + '    <p>3.2.3. адрес электронной почты (e-mail);</p>\n'
    + '    <p>3.2.4. адрес доставки Товара;</p>\n'
    + '    <p>3.2.5. место жительство Пользователя.</p>\n'
    + '    <p>3.3. Сайт защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):</p>\n'
    + '    <ul> \n'
    + '        <li>IP адрес;</li> \n'
    + '        <li>информация из cookies;</li> \n'
    + '        <li>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li> \n'
    + '        <li>время доступа;</li> \n'
    + '        <li>адрес страницы, на которой расположен рекламный блок;</li> \n'
    + '        <li>реферер (адрес предыдущей страницы).</li> \n'
    + '    </ul> \n'
    + '    <p>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.</p>\n'
    + '    <p>3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.</p>\n'
    + '    <p>3.3 Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</p>\n'
    + ' </div>\n '
    + '\n'
    + '<div>'
    + '    <span>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</span>\n'
    + '    <p>4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:</p>\n'
    + '    <p>4.1.1. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</p>\n'
    + '    <p>4.1.2. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя</p>\n'
    + '    <p>4.1.3. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>\n'
    + '    <p>4.1.4. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>\n'
    + '    <p>4.1.5. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи</p>\n'
    + '    <p>4.1.6. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта.</p>\n'
    + '    <p>4.1.7. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени сайта.</p>\n'
    + '    <p>4.1.8. Осуществления рекламной деятельности с согласия Пользователя.</p>\n'
    + '</div>\n'
    + '\n'
    + '<div>'
    + '    <span>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</span>\n'
    + '    <p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>\n'
    + '    <p>5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на Сайте тиммебель.онлайн.</p>\n'
    + '    <p>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>\n'
    + '    <p>5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</p>\n'
    + '    <p>5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>\n'
    + '    <p>5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя</p>\n'
    + '</div>\n'
    + '<div>'
    + '    <span>6. ОБЯЗАТЕЛЬСТВА СТОРОН</span>\n'
    + '    <p>6.1. Пользователь обязан:</p>\n'
    + '    <p>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.</p>\n'
    + '    <p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>\n'
    + '    <p>6.2. Администрация сайта обязана:</p>\n'
    + '    <p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>\n'
    + '    <p>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности</p>\n'
    + '    <p>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>\n'
    + '    <p>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>\n'
    + '</div>\n'
    + '<div>'
    + '    <span>7. ОТВЕТСТВЕННОСТЬ СТОРОН</span>\n'
    + '    <p>7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.</p>\n'
    + '    <p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:</p>\n'
    + '    <p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>\n'
    + '    <p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>\n'
    + '    <p>7.2.3. Была разглашена с согласия Пользователя.</p>\n'
    + '</div>\n'
    + '<div>'
    + '    <span>8. РАЗРЕШЕНИЕ СПОРОВ</span>\n'
    + '    <p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора)</p>\n'
    + '    <p>8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>\n'
    + '    <p>8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.</p>\n'
    + '    <p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.</p>\n'
    + '</div>\n'
    + '<div>'
    + '     <span>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</span>\n'
    + '    <p>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>\n'
    + '    <p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>\n'
    + '    <p>9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по адресу <a href="mailto:mebelevtimur@mail.ru.">mebelevtimur@mail.ru.</a></p>\n'
    + '    <p>9.4. Действующая Политика конфиденциальности размещена на странице по адресу <a target="_blank" href="https://тиммебель.онлайн/privacy">тиммебель.онлайн/privacy</a>.</p>\n'
    + '</div>\n'
    + '    <span>10. ОПЛАТА</span>\n'
    + '    <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard SecureCode для проведения платежа также может потребоваться ввод специального пароля.\n'
    + 'Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем МИР, Visa Int. и MasterCard Europe Sprl.</p>\n'
    + '</div>',
};
