import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './PaginationItem.module.scss';

interface PaginationNextItemProps {
  href: string;
  isLast: boolean;
}

export const PaginationNextItem = ({ href, isLast }: PaginationNextItemProps) => (
  <Link to={href} className={cn(styles.item, styles.itemNext, isLast && styles.itemDisabled)}>
    &gt;
  </Link>
);
