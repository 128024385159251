import { api as meApi } from './me.csr.generated';
import { api as logoutApi } from './logout.csr.generated';
import { TAG_USER } from './tags';
import { TAG_FAVOURITES } from '../favourites/tags';
import { TAG_CART } from '../cart';
import { TAG_HEADER } from '../header';

export { invalidateUserTags } from './invalidateUserTags';
export { useIsUserExistsQuery, useLazyIsUserExistsQuery } from './isUserExists.csr.generated';
export { TAG_USER } from './tags';
export { useMeQuery } from './me.csr.generated';
export { useLogoutMutation } from './logout.csr.generated';

export const userApi = meApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: { me: { providesTags: [TAG_USER] } },
});

logoutApi.enhanceEndpoints({
  addTagTypes: [TAG_USER, TAG_FAVOURITES],
  endpoints: {
    Logout: {
      invalidatesTags: [],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              logoutApi.util.invalidateTags(
                [
                  TAG_FAVOURITES as never,
                  TAG_CART as never,
                  TAG_USER as never,
                  TAG_HEADER as never,
                ],
              ),
            );
          }
        } catch { /* empty */ }
      },
    },
  },
});
