/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ConfirmOldPhoneAndSendNewCodeMutationVariables = Types.Exact<{
  input: Types.ConfirmOldPhoneAndSendNewCodeInput;
}>;


export type ConfirmOldPhoneAndSendNewCodeMutation = { __typename?: 'Mutation', ConfirmOldPhoneAndSendNewCode: string };


export const ConfirmOldPhoneAndSendNewCodeDocument = `
    mutation ConfirmOldPhoneAndSendNewCode($input: ConfirmOldPhoneAndSendNewCodeInput!) {
  ConfirmOldPhoneAndSendNewCode(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ConfirmOldPhoneAndSendNewCode: build.mutation<ConfirmOldPhoneAndSendNewCodeMutation, ConfirmOldPhoneAndSendNewCodeMutationVariables>({
      query: (variables) => ({ document: ConfirmOldPhoneAndSendNewCodeDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useConfirmOldPhoneAndSendNewCodeMutation } = injectedRtkApi;

