import { Container } from 'react-bootstrap';
import { Designer } from 'features/designer';
import { CategoriesList } from 'features/categories';
import { content } from 'shared/config';
import { ProductsCompilation } from 'features/products';
import { useMemo } from 'react';
import { useConstantQuery } from 'shared/api/constants';
import { LoadingHOC, Slider } from 'shared/ui';
import styles from './HomePage.module.scss';

export const HomePage = () => {
  const constantsQuery = useConstantQuery({ slug: content.slider });
  const slides = useMemo(
    () => constantsQuery.currentData?.Constant.value.image_data,
    [constantsQuery],
  );
  return (
    <div className={styles.root}>
      <LoadingHOC isLoading={constantsQuery.isLoading} className={styles.loader}>
        <Slider slides={slides} />
        <Container>
          <div className={styles.wrapper}>
            <CategoriesList title="Создайте мебель мечты" fromCatalog={false} />
          </div>
          <Designer />
          <ProductsCompilation title="Новые модели" content={content.products} />
          <section className={styles.description}>
            <p>
              «Тим столы и комоды» – новый проект «Тим мебель», создан
              по многочисленным просьбам дизайнеров интерьеров
            </p>
          </section>
        </Container>
      </LoadingHOC>
    </div>
  );
};
