import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { CatalogItem } from 'entities/catalog';
import { useConstantQuery } from 'shared/api/constants';
import { useGetProductsBySlugsQuery } from 'shared/api/catalog';
import { Paths } from 'shared/config';
import { LoadingHOC } from 'shared/ui';
import styles from './ProductsCompilation.module.scss';

interface ProductsCompilationProps {
  title: string;
  content: string;
}
export const ProductsCompilation = ({ title, content }: ProductsCompilationProps) => {
  const constantsQuery = useConstantQuery({ slug: content });
  const productsSlugs = useMemo(
    () => constantsQuery.currentData?.Constant.value.productSlugs,
    [constantsQuery],
  );
  const productsQuery = useGetProductsBySlugsQuery({ slugs: productsSlugs! });
  const products = useMemo(
    () => productsQuery.currentData?.getProductsBySlugs?.products?.data,
    [productsQuery],
  );
  return (
    <section className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <LoadingHOC isLoading={productsQuery.isLoading} className={styles.loader}>
        <div className={styles.wrapper}>
          {
              products?.map((product) => (
                <CatalogItem
                  key={product.id}
                  name={product.name}
                  slug={generatePath(
                    Paths.Product,
                    { path: product.categorySlug as string, slug: product.slug },
                  )}
                  imagePath={product.imagePath}
                />
              ))
            }
        </div>
      </LoadingHOC>
    </section>
  );
};
