/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type FavouritesShowQueryVariables = Types.Exact<{
  input: Types.FavouritesShowInput;
}>;


export type FavouritesShowQuery = { __typename?: 'Query', FavouritesShow?: { __typename?: 'FavouritesShowResponse', products?: { __typename?: 'FavouritesProductsList', data?: Array<{ __typename?: 'Cartproduct', id: string, name: string, slug: string, amount?: number, price: number, attribute_rows?: Array<string>, category?: { __typename?: 'Category', slug: string, id: string, name: string, active?: Types.YesNoEnum, image_path: string }, productValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, slug?: string, vars?: string, value: string } }>, productVariantValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, vars?: string, slug?: string, value: string } }>, productFiles?: Array<{ __typename?: 'ProductFiles', id: string, file_path: string, fileType?: { __typename?: 'ProductFileType', name: string, id: string } }> }>, paginatorInfo?: { __typename?: 'PaginatorData', count?: number, currentPage?: number, firstItem?: number, hasMorePages?: boolean, lastItem?: number, lastPage?: number, perPage?: number, total?: number } } } };


export const FavouritesShowDocument = `
    query FavouritesShow($input: FavouritesShowInput!) {
  FavouritesShow(input: $input) {
    products {
      data {
        id
        name
        slug
        category {
          slug
          id
          name
          active
          image_path
        }
        amount
        price
        attribute_rows
        productValues {
          id
          attribute_id
          attribute {
            id
            parent_attribute_id
            slug
            title
          }
          attributeValue {
            id
            parent_attribute_value_id
            slug
            vars
            value
          }
        }
        productVariantValues {
          id
          attribute_id
          attribute {
            id
            parent_attribute_id
            slug
            title
          }
          attributeValue {
            id
            parent_attribute_value_id
            vars
            slug
            value
          }
        }
        productFiles {
          id
          file_path
          fileType {
            name
            id
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    FavouritesShow: build.query<FavouritesShowQuery, FavouritesShowQueryVariables>({
      query: (variables) => ({ document: FavouritesShowDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFavouritesShowQuery, useLazyFavouritesShowQuery } = injectedRtkApi;

