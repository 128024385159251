/* eslint-disable no-plusplus */
import { IncDecInput } from 'shared/ui';
import { Cartproduct } from 'shared/api';
import { useCartDecreaseAmountMutation, useCartIncreaseAmountMutation } from 'shared/api/cart';
import { useRef } from 'react';

interface IncDecCartItemProps {
  product: Cartproduct;
}
export const IncDecCartItem = ({ product }: IncDecCartItemProps) => {
  const [incCart, { isLoading: isIncLoading }] = useCartIncreaseAmountMutation();
  const [decCart, { isLoading: isDecLoading }] = useCartDecreaseAmountMutation();
  const count = useRef(product.amount ?? 1);
  const onInc = () => {
    if (isIncLoading || count.current === 999) {
      return;
    }
    count.current++;
    const input = { product_id: product.id };
    incCart({ input });
  };
  const onDec = () => {
    if (isDecLoading || count.current === 1) {
      return;
    }
    count.current--;
    const input = { product_id: product.id };
    decCart({ input });
  };
  return (
    <IncDecInput
      value={product?.amount}
      onInc={onInc}
      onDec={onDec}
      isIncLoading={isIncLoading}
      isDecLoading={isDecLoading}
    />
  );
};
