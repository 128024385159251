/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ChangePhoneWithPasswordMutationVariables = Types.Exact<{
  input: Types.ChangePhoneWithPasswordInput;
}>;


export type ChangePhoneWithPasswordMutation = { __typename?: 'Mutation', ChangePhoneWithPassword: string };


export const ChangePhoneWithPasswordDocument = `
    mutation ChangePhoneWithPassword($input: ChangePhoneWithPasswordInput!) {
  ChangePhoneWithPassword(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ChangePhoneWithPassword: build.mutation<ChangePhoneWithPasswordMutation, ChangePhoneWithPasswordMutationVariables>({
      query: (variables) => ({ document: ChangePhoneWithPasswordDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangePhoneWithPasswordMutation } = injectedRtkApi;

