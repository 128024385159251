/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ChangeEmailWithoutPasswordStep1MutationVariables = Types.Exact<{
  input: Types.ChangeEmailWithoutPasswordStep1Input;
}>;


export type ChangeEmailWithoutPasswordStep1Mutation = { __typename?: 'Mutation', ChangeEmailWithoutPasswordStep1: string };


export const ChangeEmailWithoutPasswordStep1Document = `
    mutation ChangeEmailWithoutPasswordStep1($input: ChangeEmailWithoutPasswordStep1Input!) {
  ChangeEmailWithoutPasswordStep1(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ChangeEmailWithoutPasswordStep1: build.mutation<ChangeEmailWithoutPasswordStep1Mutation, ChangeEmailWithoutPasswordStep1MutationVariables>({
      query: (variables) => ({ document: ChangeEmailWithoutPasswordStep1Document, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeEmailWithoutPasswordStep1Mutation } = injectedRtkApi;

