import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'entities/user';
import { useNavigate } from 'react-router';
import { Paths } from 'shared/config';
import { useContext } from 'react';
import {
  useChangePhoneWithPasswordMutation,
  useConfirmChangePhoneWithPasswordMutation,
  useConfirmNewPhoneMutation,
  useConfirmOldPhoneAndSendNewCodeMutation,
} from 'shared/api/profile/phone';
import { normalizePhone } from 'shared/lib';
import { ChangePhoneContext } from './ChangePhoneContext';

interface FormData {
  confirmCode: string;
}
export const useConfirmCodeForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>();
  const { user } = useUser();
  const navigate = useNavigate();
  const [confirmPhoneWithPassword] = useConfirmChangePhoneWithPasswordMutation();
  const [changePhoneWithPassword] = useChangePhoneWithPasswordMutation();
  const [confirmOldPhone] = useConfirmOldPhoneAndSendNewCodeMutation();
  const [confirmPhoneWithoutPassword] = useConfirmNewPhoneMutation();
  const { steps: { step1, step2 } } = useContext(ChangePhoneContext);
  const setResendCode = () => {
    if (user?.lastPasswordResetAt) {
      const input = {
        new_phone: step1?.phone ? normalizePhone(step1?.phone) : normalizePhone(step2?.new_phone),
        password: step2.password,
      };
      changePhoneWithPassword({ input });
    }
    if (!user?.lastPasswordResetAt) {
      const input = {
        new_phone: step1?.phone ? normalizePhone(step1?.phone) : normalizePhone(step2?.new_phone),
        code: step2.code,
      };
      confirmOldPhone({ input });
    }
  };
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input = {
      new_phone: step1?.phone ? normalizePhone(step1?.phone) : normalizePhone(step2?.new_phone),
      code: data.confirmCode,
    };
    if (user?.lastPasswordResetAt) {
      confirmPhoneWithPassword({ input })
        .unwrap()
        .then(() => {
          navigate(Paths.Profile);
        })
        .catch((error) => {
          setError('confirmCode', { type: '', message: error.message });
        });
    }
    if (!user?.lastPasswordResetAt) {
      confirmPhoneWithoutPassword({ input })
        .unwrap()
        .then(() => {
          navigate(Paths.Profile);
        })
        .catch((error) => {
          reset();
          setError('confirmCode', { type: '', message: error.message });
        });
    }
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    setError,
    reset,
    clearErrors,
    phone: step1?.phone ?? step2?.new_phone,
    setResendCode,
  };
};
