/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type GetCartAndFavouritesCountQueryVariables = Types.Exact<{
  input: Types.FavouritesShowInput;
}>;


export type GetCartAndFavouritesCountQuery = { __typename?: 'Query', FavouritesShow?: { __typename?: 'FavouritesShowResponse', products?: { __typename?: 'FavouritesProductsList', paginatorInfo?: { __typename?: 'PaginatorData', total?: number } } }, Cart?: { __typename?: 'CartFront', count: number } };


export const GetCartAndFavouritesCountDocument = `
    query getCartAndFavouritesCount($input: FavouritesShowInput!) {
  FavouritesShow(input: $input) {
    products {
      paginatorInfo {
        total
      }
    }
  }
  Cart {
    count: products_count
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    getCartAndFavouritesCount: build.query<GetCartAndFavouritesCountQuery, GetCartAndFavouritesCountQueryVariables>({
      query: (variables) => ({ document: GetCartAndFavouritesCountDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCartAndFavouritesCountQuery, useLazyGetCartAndFavouritesCountQuery } = injectedRtkApi;

