/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type LeadsCreateLeadMutationVariables = Types.Exact<{
  input: Types.CreateLeadInput;
}>;


export type LeadsCreateLeadMutation = { __typename?: 'Mutation', LeadsCreateLead: string };


export const LeadsCreateLeadDocument = `
    mutation LeadsCreateLead($input: CreateLeadInput!) {
  LeadsCreateLead(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LeadsCreateLead: build.mutation<LeadsCreateLeadMutation, LeadsCreateLeadMutationVariables>({
      query: (variables) => ({ document: LeadsCreateLeadDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLeadsCreateLeadMutation } = injectedRtkApi;

