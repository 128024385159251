/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, Button, IconButton, TextInput } from 'shared/ui';
import { FormContainer } from 'features/profile/edit/data';
import { useFormErrorMessages } from 'shared/lib';
import { Paths } from 'shared/config';
import { Link } from 'react-router-dom';
import { useUser } from 'entities/user';
import { useNameForm } from '../model';
import styles from './NameChangeForm.module.scss';

export const NameChangeForm = () => {
  const {
    register,
    onSubmit,
    errors,
    name,
    surname,
    middlename,
  } = useNameForm();
  const validateNoSpaces = (value: string) => value.trim().length === value.length || 'Пробелы не допускаются';
  const { user } = useUser();
  const errorMessage = useFormErrorMessages();
  const maxLengthRule = {
    value: 16,
    message: 'Максимум 16 символов',
  };
  return (
    <FormContainer
      title={user?.name ? 'Редактировать ФИО' : 'Заполнить ФИО'}
      onSubmit={onSubmit}
      className={styles.root}
    >
      <Link to={Paths.Profile}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <TextInput
        id="surname"
        label="Фамилия"
        hasError={errors.surname != null}
        required
        defaultValue={surname}
        help={errors.surname ? errors.surname.message : undefined}
        {...register('surname', {
          required: errorMessage.required,
          validate: validateNoSpaces,
          maxLength: maxLengthRule,
        })}
      />
      <TextInput
        id="name"
        label="Имя"
        hasError={errors.name != null}
        help={errors.name ? errors.name.message : undefined}
        required
        defaultValue={name}
        {...register('name', {
          required: errorMessage.required,
          validate: validateNoSpaces,
          maxLength: maxLengthRule,
        })}
      />
      <TextInput
        id="middlename"
        label="Отчество"
        hasError={errors.middlename != null}
        defaultValue={middlename}
        help={errors.middlename ? errors.middlename.message : undefined}
        {...register('middlename', { validate: validateNoSpaces, maxLength: maxLengthRule })}
      />
      <Button type="submit" className={styles.submit}>Сохранить</Button>
    </FormContainer>
  );
};
