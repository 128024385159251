import cn from 'classnames';
import { FavoriteIcon } from 'shared/ui';
import { useFavouritesDeleteProductMutation } from 'shared/api/favourites';
import { useState } from 'react';
import styles from './AddToFavorite.module.scss';
import { useAddToFavorite } from '../model';

interface AddToFavoriteProps {
  productId?: string;
  onClick?: () => void;
  isInFavorite?: boolean;
  disabled?: boolean;
}
export const AddToFavorite = (
  {
    productId,
    onClick,
    isInFavorite,
    disabled,
  }: AddToFavoriteProps,
) => {
  const { addToFavorite, inProcess } = useAddToFavorite(productId);
  const [inFavorite, setInFavorite] = useState(isInFavorite);
  const [removeFromFavourites] = useFavouritesDeleteProductMutation();
  const handleClick = () => {
    if (isInFavorite) {
      removeFromFavourites({ id: productId ?? '' })
        .unwrap()
        .then(() => setInFavorite(false));
    } else {
      addToFavorite();
      onClick?.();
      setInFavorite(true);
    }
  };
  return (
    <button
      type="button"
      disabled={disabled || inProcess}
      className={styles.root}
      onClick={handleClick}
    >
      <FavoriteIcon
        className={cn(styles.icon, inFavorite && styles.iconInFavorite)}
      />
    </button>
  );
};
