/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type GetProductsBySlugsQueryVariables = Types.Exact<{
  slugs: Types.Scalars['String']['input'];
}>;


export type GetProductsBySlugsQuery = { __typename?: 'Query', getProductsBySlugs: { __typename?: 'CatalogResponse', products?: { __typename?: 'Subproducts', data?: Array<{ __typename?: 'Subproduct', id: string, name: string, slug: string, imagePath?: string, categorySlug?: string }> } } };


export const GetProductsBySlugsDocument = `
    query getProductsBySlugs($slugs: String!) {
  getProductsBySlugs(slugs: $slugs) {
    products {
      data {
        id
        name
        imagePath: first_image_url
        slug
        categorySlug: category_slug
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    getProductsBySlugs: build.query<GetProductsBySlugsQuery, GetProductsBySlugsQueryVariables>({
      query: (variables) => ({ document: GetProductsBySlugsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetProductsBySlugsQuery, useLazyGetProductsBySlugsQuery } = injectedRtkApi;

