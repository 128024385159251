import { generatePath, Link } from 'react-router-dom';
import cn from 'classnames';
import { OrderStatus } from 'entities/order-history/ui/OrderStatus';
import { orderStatusMap } from 'entities/order-history/config';
import { ArrowBackIcon } from 'shared/ui';
import { Paths } from 'shared/config';
import { formatDate, formatPrice } from 'shared/lib/format';
import { ProductRow } from 'entities/product/row';
import { OrderCardType } from 'entities/order-history/types';
import styles from './OrderCard.module.scss';
import { OrderActions } from './OrderActions';
import { OrderProductsDropdown } from './OrderProductsDropdown';

interface OrderCardProps {
  orderCard: OrderCardType;
}
export const OrderCard = ({ orderCard }: OrderCardProps) => {
  const {
    id,
    status,
    address,
    comission,
    createdAt,
    total,
    fio,
    products,
    paymentLink,
  } = orderCard;
  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <Link to={Paths.ProfileHistory}>
            <div className={styles.back}>
              <ArrowBackIcon className={styles.backIcon} />
            </div>
          </Link>
          <div className={styles.title}>
            <span className={styles.number}>{`Заказ №${id}`}</span>
            <OrderStatus status={orderStatusMap[status.id]} className={styles.status} />
          </div>
        </div>
        <div className={styles.actions}>
          <OrderActions status={status} paymentLink={paymentLink} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.item}>
          <span className={styles.property}>ФИО</span>
          <span className={styles.value}>{fio}</span>
        </div>
        <div className={styles.item}>
          <span className={styles.property}>Адрес</span>
          <span className={styles.value}>{address}</span>
        </div>
        <div className={styles.price}>
          <div className={styles.item}>
            <span className={styles.property}>Сумма</span>
            <span className={styles.value}>{formatPrice(total)}</span>
          </div>
          {
            comission && (
              <div className={styles.item}>
                <span className={styles.property}>Комиссия</span>
                <span className={styles.value}>{formatPrice(comission)}</span>
              </div>
            )
          }
        </div>
        <div className={styles.item}>
          <span className={styles.property}>Дата</span>
          <span className={styles.value}>{formatDate(createdAt, true)}</span>
        </div>
      </div>
      <div className={cn(styles.actions, styles.actionsMobile)}>
        <OrderActions status={status} paymentLink={paymentLink} />
      </div>
      <div className={styles.products}>
        {products?.map((product) => (
          <Link
            to={
            generatePath(Paths.Product, { path: product!.category!.slug, slug: product.slug })
          }
          >
            <ProductRow product={product} className={styles.product} withAmount />
          </Link>
        ))}
      </div>
      <OrderProductsDropdown products={products} />
    </section>
  );
};
