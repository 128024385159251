import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'entities/user';
import { MultistepPagination, normalizePhone } from 'shared/lib';
import { useContext } from 'react';
import { useChangePhoneWithPasswordMutation } from 'shared/api/profile/phone';
import { ChangePhoneContext } from './ChangePhoneContext';

interface FormData {
  password: string;
}
export const usePasswordConfirmationForm = (pagination: MultistepPagination) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<FormData>();
  const { user } = useUser();
  const { setStep, steps: { step3 } } = useContext(ChangePhoneContext);
  const [changePhoneWithPassword] = useChangePhoneWithPasswordMutation();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input = {
      new_phone: normalizePhone(step3.phone),
      password: data.password,
    };
    changePhoneWithPassword({ input })
      .unwrap()
      .then(() => {
        setStep(2, input);
        pagination.goTo(2);
      })
      .catch((e) => setError('password', { type: '', message: e.message }));
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    defaultValue: user?.email,
  };
};
