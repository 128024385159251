import { SubmitHandler, UseFormReset, UseFormResetField, UseFormSetValue } from 'react-hook-form';
import { useLeadsCreateLeadMutation } from 'shared/api/forms';
import { normalizePhone } from 'shared/lib';
import { Dispatch, RefObject, SetStateAction, useState } from 'react';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';

interface FormData {
  name: string;
  phone: string;
  comment: string;
  response: string;
}

interface UseCallBackFormProps {
  captchaRef: RefObject<Reaptcha>;
  resetField: UseFormResetField<FormData>;
  setCaptcha: Dispatch<SetStateAction<string>>;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  type: string;
  setPopupVisible?: Dispatch<SetStateAction<boolean>>;
  setEnhancedSuccessPopup?:Dispatch<SetStateAction<boolean>>;
  withProductLink?: boolean;
  reset: UseFormReset<FormData>;
  setValue: UseFormSetValue<FormData>;
}

export const useCallbackForm = ({
  captchaRef,
  resetField,
  setCaptcha,
  setSubmitDisabled,
  type,
  setPopupVisible,
  setEnhancedSuccessPopup,
  withProductLink,
  reset,
  setValue,
}: UseCallBackFormProps) => {
  const [createLead] = useLeadsCreateLeadMutation();
  const [isSuccessPopupVisible, setSuccessIsPopupVisible] = useState(false);
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setSubmitDisabled(true);
    if (data.response == null) {
      captchaRef.current?.execute();
      return;
    }
    const currentUrl = window.location.href;
    const input = {
      name: data.name,
      phone: normalizePhone(data.phone),
      comment: data.comment,
      type,
      product_link: withProductLink ? currentUrl : '',
      response: data.response,
    };
    createLead({ input })
      .unwrap()
      .then(() => {
        setPopupVisible?.(false);
        if (setEnhancedSuccessPopup) {
          setEnhancedSuccessPopup(true);
        } else {
          setSuccessIsPopupVisible(true);
        }
        reset();
        setValue('phone', '+7 (___) ___-__-__');
      })
      .finally(() => {
        setCaptcha(nanoid());
        resetField('response');
        setSubmitDisabled(false);
      });
  };

  return {
    onSubmit,
    isSuccessPopupVisible,
    setSuccessIsPopupVisible,
  };
};
