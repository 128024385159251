/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { FormContainer } from 'entities/auth';
import { Button, CheckboxInput, CodeInput, IconButton, ResendCode, Popup } from 'shared/ui';
import { timestamp, MultistepPageProps } from 'shared/lib';
import { ArrowBackIcon } from 'shared/ui/icons';
import { Link } from 'react-router-dom';
import { Paths } from 'shared/config';
import { useCodeForm } from '../model';
import styles from './CodeForm.module.scss';

export const CodeForm = ({ pagination }: MultistepPageProps) => {
  const {
    onSubmit,
    setValue,
    contact,
    errors,
    register,
    isPopupVisible,
    setPopupVisible,
    clearErrors,
    disabled,
    setResendCode,
  } = useCodeForm();
  const [codeRequestTime, setCodeRequestTime] = useState(timestamp());
  const onCodeResend = (requestTime: number) => {
    setCodeRequestTime(requestTime);
  };
  const isPhone = contact?.indexOf('+7') === 0;
  const description = () => {
    if (isPhone) {
      return (
        <p>
          Введите последние 4 цифры номера входящего звонка на ваш номер
          <span>{contact}</span>
        </p>
      );
    }
    return (
      <p>
        Введите код или перейдите по ссылке, которые были отправлены на почту
        <span>{contact}</span>
      </p>
    );
  };
  return (
    <>
      <IconButton className={styles.stepBack} onClick={pagination.back}>
        <ArrowBackIcon />
      </IconButton>
      <FormContainer
        title="Подтверждение"
        id="login-by-code"
        onSubmit={onSubmit}
        description={description()}
        className={styles.root}
      >
        <CodeInput
          setCode={(code) => {
            clearErrors();
            setValue('code', code);
            onSubmit();
          }}
          disabled={disabled}
          hasError={errors.code != null}
          help={errors.code?.message}
        />
        <ResendCode
          startTime={codeRequestTime}
          onResend={onCodeResend}
          setResendCode={setResendCode}
          isPhone={isPhone}
        />
        <CheckboxInput
          label="Я дизайнер"
          {...register('designer')}
        />
      </FormContainer>
      <Popup
        isPopupVisible={isPopupVisible}
        setPopupVisible={setPopupVisible}
        redirect
        size="lg"
      >
        <h2 className="popup_title">Вы успешно зарегистрировались!</h2>
        <p className={styles.subtitle}>
          Вы можете установить пароль для входа в
          <Link to={Paths.Profile}> личном кабинете</Link>
        </p>
        <Link to={Paths.Root} className={styles.link}>
          <Button className={styles.btn}>
            На главную
          </Button>
        </Link>
      </Popup>
    </>
  );
};
