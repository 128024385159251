export const offer = {
  title: 'Публичная оферта',
  content: '<div>\n'
    + '    <h4>Интернет-магазин «Тим столы и комоды», расположенный на доменном имени тиммебель.онлайн, ИП Изегов Т.С., и юридическим адресом , в лице Изегова Тимура Сергеевича, действующего на основании Выписки из ЕГРИП 322246800053783 от 25 апреля 2022г. именуемое в дальнейшем «Продавец», публикует Публичную оферту о продаже Товара дистанционным способом.</h4>\n'
    + '\n'
    + ' <div>'
    + '    <span>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</span>\n'
    + '    <p>1.1. Публичная оферта (далее — «Оферта») — публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом договор купли-продажи товара дистанционным способом (далее — «Договор») на условиях, содержащихся в настоящей Оферте, включая все Приложения.</p>\n'
    + '    <p>1.2. Заказ Товара на сайте Интернет-магазина — позиции указанные Покупателем из ассортимента Товара, предложенного к продаже, при оформлении заявки на приобретение Товара на сайте Интернет-магазина или через Оператора.</p>\n'
    + '    <p>1.3 Покупатели — физические лица, которые отправили заявку на сайте тиммебель.онлайн и/или совершили заказ на сайте тиммебель.онлайн.</p>\n'
    + ' </div>\n '
    + '\n'
    + '<div>'
    + '    <span>2. ОБЩИЕ ПОЛОЖЕНИЯ</span>\n'
    + '    <p>2.1. Заказ Покупателем Товара, размещённого на сайте Интернет-магазина означает, что Покупатель согласен со всеми условиями настоящей Оферты.</p>\n'
    + '    <p>2.2. Администрация сайта Интернет-магазина имеет право вносить изменения в Оферту без уведомления Покупателя.</p>\n'
    + '    <p>2.3. Срок действия Оферты не ограничен, если иное не указано на сайте Интернет-магазина.</p>\n'
    + '    <p>2.4. Продавец предоставляет Покупателю полную и достоверную информацию о Товаре, включая информацию об основных потребительских свойствах Товара.</p>\n'
    + '\n'
    + '</div>\n '
    + '<div>'
    + '    <span>3. ЦЕНА ТОВАРА</span>\n'
    + '    <p>3.1. Цена на каждую позицию Товара указана на сайте Интернет-магазина.</p>\n'
    + '    <p>3.2. Продавец имеет право в одностороннем порядке изменить цену на любую позицию Товара.</p>\n'
    + '    <p>3.3. В случае изменения цены на заказанный Товар Продавец обязуется в течение 3х рабочих дней проинформировать Покупателя об изменении цены Товара.</p>\n'
    + '    <p>3.4. Покупатель вправе подтвердить либо аннулировать Заказ на приобретение Товара, если цена изменена Продавцом после оформления Заказа</p>\n'
    + '    <p>3.5. Изменение Продавцом цены на оплаченный Покупателем Товар не допускается.</p>\n'
    + '    <p>3.6. Продавец указывает стоимость доставки Товара на сайте Интернет-магазина либо сообщает Покупателю при оформлении заказа Оператором.</p>\n'
    + '    <p>3.7. Обязательства Покупателя по оплате Товара считаются исполненными с момента поступления Продавцом денежных средств.</p>\n'
    + '    <p>3.8. Расчеты между Продавцом и Покупателем за Товар производятся способами, указанными на сайте Интернет-магазина в разделе «Оплата».</p>\n'
    + ' </div>\n '
    + '\n'
    + '<div>'
    + '    <span>4. ОФОРМЛЕНИЕ ЗАКАЗА</span>\n'
    + '    <p>4.1. Заказ Товара осуществляется Покупателем через Оператора по телефону <a href="tel:+79964275758">8-996-427-57-58</a> или через сервис сайта Интернет-магазина тиммебель.онлайн.</p>\n'
    + '    <p>4.2. При регистрации на сайте Интернет-магазина Покупатель обязуется предоставить следующую регистрационную информацию:</p>\n'
    + '    <p>4.2.1. фамилия, имя, отчество Покупателя или указанного им лица (получателя);</p>\n'
    + '    <p>4.2.2. адрес, по которому следует доставить Товар (если доставка до адреса Покупателя);</p>\n'
    + '    <p>4.2.3. адрес электронной почты;</p>\n'
    + '    <p>4.2.4. контактный телефон.</p>\n'
    + '    <p>4.3. Наименование, количество выбранного Покупателем Товара указываются в корзине Покупателя на сайте Интернет-магазина</p>\n'
    + '    <p>4.4. Если Продавцу необходима дополнительная информация, он вправе запросить ее у Покупателя. В случае не предоставления необходимой информации Покупателем, Продавец не несет ответственности за выбранный Покупателем Товар</p>\n'
    + '    <p>4.5. При оформлении Заказа через Оператора (п. 4.1. настоящей Оферты) Покупатель обязуется предоставить информацию, указанную в п. 4.2. настоящей Оферты</p>\n'
    + '    <p>4.6. Принятие Покупателем условий настоящей Оферты осуществляется посредством внесения Покупателем соответствующих данных в регистрационную форму на сайте Интернет-магазина или при оформлении Заказа через Оператора. После оформления Заказа через Оператора данные о Покупателе регистрируются в базе данных Продавца. Утвердив Заказ выбранного Товара, Покупатель предоставляет Оператору необходимую информацию в соответствии с порядком, указанном в п. 4.2. настоящей Оферты.</p>\n'
    + '    <p>4.7. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении Заказа.</p>\n'
    + '    <p>4.8. Покупатель несет ответственность за достоверность предоставленной информации при оформлении Заказа</p>\n'
    + '    <p>4.9. Договор купли-продажи дистанционным способом между Продавцом и Покупателем считается заключенным с момента выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату Товара.</p>\n'
    + '</div>\n'
    + '\n'
    + '<div>'
    + '    <span>5. ДОСТАВКА И ПЕРЕДАЧА ТОВАРА ПОКУПАТЕЛЮ</span>\n'
    + '    <p>5.1. Продавец оказывает Покупателю услуги по доставке Товара одним из способов указанных на сайте Интернет-магазина.</p>\n'
    + '    <p>5.2. Если Договор купли-продажи товара дистанционным способом (далее — Договор) заключен с условием о доставке Товара Покупателю, Продавец обязан в установленный Договором срок доставить Товар в место, указанное Покупателем, а если место доставки Товара Покупателем не указано, то по месту его жительства или регистрации.</p>\n'
    + '    <p>5.3. Место доставки Товара Покупатель указывает при оформлении Заказа на приобретение Товара.</p>\n'
    + '    <p>5.4. Срок доставки Товара Покупателю состоит из срока обработки заказа и срока доставки.</p>\n'
    + '    <p>5.5. Доставленный Товар передается Покупателю, а при отсутствии Покупателя — любому лицу, предъявившему квитанцию или иной документ, подтверждающий заключение Договора или оформление доставки Товара.</p>\n'
    + '    <p>5.6. Информация о Товаре доводится до сведения Покупателя в технической документации, прилагаемой к Товару, на этикетках, путем нанесения маркировки или иным способом, принятым для отдельных видов товаров.</p>\n'
    + '    <p>5.7. Сведения об обязательном подтверждении соответствия Товара представляются в порядке и способами, которые установлены законодательством Российской Федерации о техническом регулировании, и включают в себя сведения о номере документа, подтверждающего такое соответствие, о сроке его действия и об организации, его выдавшей</p>\n'
    + '</div>\n'
    + '<div>'
    + '    <span>6. РЕКВИЗИТЫ ПРОДАВЦА</span>\n'
    + '    <p>ИП Изегоев Т.С</p>\n'
    + '    <p>Адрес регистрации: 66003г.Красноярск ул.транзитная 6 ,кв.36</p>\n'
    + '    <p>Адрес регистрации: 66003г.Красноярск ул.транзитная 6 ,кв.36</p>\n'
    + '    <p>фактический адрес: 660023,г.Красноярск ул.26 бакинских Коммисаров д.8 стр.66</p>\n'
    + '    <p>ИНН: 422377698001</p>\n'
    + '    <p>ОГРНИП: 322246800053783</p>\n'
    + '    <p>Номер расчетного счета: 40802810031000072528</p>\n'
    + '    <p>Наименование банка: ПАО Сбербанк</p>\n'
    + '    <p>Корреспондентский счет:30101810800000000627</p>\n'
    + '    <p>БИК: 040407627</p>\n'
    + '    <p>E-mail: mebelevtimur@mail.ru</p>\n'
    + '    <p>Телефон: 8 913 188-57-58</p>\n'
    + '</div>\n'
    + '    <p>Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо пунктом оферты, Вы вправе отказаться от покупки Товаров, предоставляемых Продавцом, и не совершать действий, указанный в п. 2.1. настоящей Оферты.</p>\n'
    + '</div>',
};
