/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { FormContainer } from 'features/profile/edit/data';
import { CodeInput, IconButton, ResendCode } from 'shared/ui';
import { timestamp, MultistepPageProps } from 'shared/lib';
import { ArrowBackIcon } from 'shared/ui/icons';
import styles from 'features/profile/edit/data/ui/CodeForm.module.scss';
import { useConfirmCodeForm } from '../model';

export const ConfirmCodeForm = ({ pagination }: MultistepPageProps) => {
  const {
    onSubmit,
    setValue,
    errors,
    setResendCode,
    clearErrors,
    email,
  } = useConfirmCodeForm();
  const [codeRequestTime, setCodeRequestTime] = useState(timestamp());
  const onCodeResend = (requestTime: number) => {
    setCodeRequestTime(requestTime);
  };
  return (
    <FormContainer
      title="Подтверждение"
      id="confirm-email"
      onSubmit={onSubmit}
      className={styles.root}
      rootClass
      description={(
        <>
          <p className={styles.description}>
            Введите код или перейдите по ссылке, которые были отправлены на почту
            <span className={styles.contact}>{` ${email}`}</span>
          </p>
          <p className={styles.description}>
            Подтверждение нужно
            <br />
            в целях безопасности аккаунта
          </p>
        </>
      )}
    >
      <IconButton className="stepBack" onClick={() => pagination.goTo(0)}>
        <ArrowBackIcon />
      </IconButton>
      <CodeInput
        setCode={(code) => {
          clearErrors();
          setValue('confirmCode', code);
          onSubmit();
        }}
        hasError={errors.confirmCode != null}
        help={errors.confirmCode?.message}
      />
      <ResendCode
        startTime={codeRequestTime}
        onResend={onCodeResend}
        setResendCode={setResendCode}
      />
    </FormContainer>
  );
};
