/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ConfirmChangeEmailWithPasswordMutationVariables = Types.Exact<{
  input: Types.ConfirmChangeEmailWithPasswordInput;
}>;


export type ConfirmChangeEmailWithPasswordMutation = { __typename?: 'Mutation', ConfirmChangeEmailWithPassword: string };


export const ConfirmChangeEmailWithPasswordDocument = `
    mutation ConfirmChangeEmailWithPassword($input: ConfirmChangeEmailWithPasswordInput!) {
  ConfirmChangeEmailWithPassword(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ConfirmChangeEmailWithPassword: build.mutation<ConfirmChangeEmailWithPasswordMutation, ConfirmChangeEmailWithPasswordMutationVariables>({
      query: (variables) => ({ document: ConfirmChangeEmailWithPasswordDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useConfirmChangeEmailWithPasswordMutation } = injectedRtkApi;

