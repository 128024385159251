import { SubmitHandler, UseFormResetField, UseFormSetError } from 'react-hook-form';
import { Dispatch, RefObject, SetStateAction, useContext } from 'react';
import { MultistepPagination } from 'shared/lib/multistep/types';
import { normalizePhone, regex } from 'shared/lib';
import type { LoginByCodeStep1Input } from 'shared/api';
import { useLoginStep1Mutation } from 'shared/api/login-by-code';
import { nanoid } from '@reduxjs/toolkit';
import Reaptcha from 'reaptcha';
import { LoginByCodeContext } from './LoginByCodeContext';

interface FormData {
  contact: string;
  response: string;
}

interface UseContactFormProps {
  pagination: MultistepPagination;
  captchaRef: RefObject<Reaptcha>;
  resetField: UseFormResetField<FormData>;
  setCaptcha: Dispatch<SetStateAction<string>>;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  setError: UseFormSetError<FormData>;
}
export function useContactForm({
  pagination,
  captchaRef,
  resetField,
  setCaptcha,
  setSubmitDisabled,
  setError,
}: UseContactFormProps) {
  const { setStep } = useContext(LoginByCodeContext);
  const [loginStep1] = useLoginStep1Mutation();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setSubmitDisabled(true);
    if (data.response == null) {
      captchaRef.current?.execute();
      return;
    }
    const input: LoginByCodeStep1Input = { response: data.response };
    if (regex.email.test(data.contact)) {
      input.email = data.contact;
    } else if (regex.phone.test(data.contact)) {
      input.phone = normalizePhone(data.contact);
    }
    loginStep1({ input })
      .unwrap()
      .then(() => {
        setStep(1, data);
        pagination.next();
      })
      .catch((error) => {
        setError('contact', { type: '', message: error.message });
      })
      .finally(() => {
        setCaptcha(nanoid());
        resetField('response');
        setSubmitDisabled(false);
      });
  };

  return onSubmit;
}
