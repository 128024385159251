import { useState } from 'react';
import {
  ChangePhoneContext,
  CodeForm,
  PasswordConfirmationForm,
  PhoneChangeForm,
  useChangePhoneContext,
  ConfirmCodeForm,
} from 'features/profile/edit/phone';
import { Container } from 'react-bootstrap';
import { ProfileMenu } from 'entities/profile/menu';
import { Multistep } from 'shared/lib';
import styles from './ProfileChangePhonePage.module.scss';

export const ProfileChangePhonePage = () => {
  const stepState = useState(0);
  const changePhoneContextValue = useChangePhoneContext();
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <ProfileMenu />
          <ChangePhoneContext.Provider value={changePhoneContextValue}>
            <Multistep
              state={stepState}
              steps={[
                PhoneChangeForm,
                CodeForm,
                ConfirmCodeForm,
                PasswordConfirmationForm,
              ]}
            />
          </ChangePhoneContext.Provider>
        </div>
      </Container>
    </div>
  );
};
