/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { FormContainer } from 'features/profile/edit/data';
import { CodeInput, IconButton, ResendCode } from 'shared/ui';
import { timestamp, MultistepPageProps, formatPhone, normalizePhone } from 'shared/lib';
import { ArrowBackIcon } from 'shared/ui/icons';
import styles from 'features/profile/edit/data/ui/CodeForm.module.scss';
import { useCodeForm } from '../model';

export const CodeForm = ({ pagination }: MultistepPageProps) => {
  const {
    onSubmit,
    setValue,
    errors,
    setResendCode,
    clearErrors, phone,
  } = useCodeForm(pagination);
  const [codeRequestTime, setCodeRequestTime] = useState(timestamp());
  const onCodeResend = (requestTime: number) => {
    setCodeRequestTime(requestTime);
  };
  const description = (
    <>
      <p>
        Введите последние 4 цифры номера входящего
        звонка на ваш номер
        <br />
        <span>{formatPhone(normalizePhone(phone))}</span>
      </p>
      <p className={styles.description}>
        Подтверждение нужно
        <br />
        в целях безопасности аккаунта
      </p>
    </>
  );
  return (
    <FormContainer
      title="Подтверждение"
      id="change-phone"
      onSubmit={onSubmit}
      className={styles.root}
      rootClass
      description={description}
    >
      <IconButton className="stepBack" onClick={pagination.back}>
        <ArrowBackIcon />
      </IconButton>
      <CodeInput
        setCode={(code) => {
          clearErrors();
          setValue('code', code);
          onSubmit();
        }}
        hasError={errors.code != null}
        help={errors.code?.message}
      />
      <ResendCode
        startTime={codeRequestTime}
        onResend={onCodeResend}
        setResendCode={setResendCode}
        isPhone
      />
    </FormContainer>
  );
};
