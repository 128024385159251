/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ConfirmOldEmailAndSendNewCodeMutationVariables = Types.Exact<{
  input: Types.ConfirmOldEmailAndSendNewCodeInput;
}>;


export type ConfirmOldEmailAndSendNewCodeMutation = { __typename?: 'Mutation', ConfirmOldEmailAndSendNewCode: string };


export const ConfirmOldEmailAndSendNewCodeDocument = `
    mutation ConfirmOldEmailAndSendNewCode($input: ConfirmOldEmailAndSendNewCodeInput!) {
  ConfirmOldEmailAndSendNewCode(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ConfirmOldEmailAndSendNewCode: build.mutation<ConfirmOldEmailAndSendNewCodeMutation, ConfirmOldEmailAndSendNewCodeMutationVariables>({
      query: (variables) => ({ document: ConfirmOldEmailAndSendNewCodeDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useConfirmOldEmailAndSendNewCodeMutation } = injectedRtkApi;

