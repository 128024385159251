import { CatalogsItem } from 'entities/catalogs';
import { useMemo } from 'react';
import { useConstantQuery } from 'shared/api/constants';
import { content } from 'shared/config';
import { LoadingHOC } from 'shared/ui';
import { groupBy } from 'shared/lib';
import styles from './CatalogsPages.module.scss';

export const PdfCatalogPage = () => {
  const constantQuery = useConstantQuery({ slug: content.pdfCatalog });
  const pdfCatalogs = useMemo(
    () => constantQuery.currentData?.Constant.value.image_data ?? [],
    [constantQuery],
  );
  const catalogsByCategories = useMemo(() => (
    groupBy(
      pdfCatalogs,
      (catalog) => catalog.title,
    )
  ), [pdfCatalogs]);
  return (
    <div className={styles.root}>
      <LoadingHOC isLoading={constantQuery.isLoading}>
        {
          Object.entries(catalogsByCategories).map(([title, list]) => (
            <div className={styles.wrapper} key={title}>
              <h2 className={styles.title}>{title}</h2>
              <div className={styles.items}>
                {
                    list.map((item) => (
                      <CatalogsItem item={item} key={item.path} />
                    ))
                  }
              </div>
            </div>
          ))
        }
      </LoadingHOC>
    </div>
  );
};
