import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useFavouritesShowQuery } from 'shared/api/favourites';

export const useGetFavourites = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Number(searchParams.get('page') ?? '1');
  const input = {
    page: pageNumber,
    first: 5,
  };
  const query = useFavouritesShowQuery({ input });
  const favourites = useMemo(
    () => query.currentData?.FavouritesShow?.products?.data,
    [query.currentData],
  );
  const paginatorInfo = useMemo(
    () => query.currentData?.FavouritesShow?.products?.paginatorInfo,
    [query.currentData],
  );
  if (paginatorInfo?.lastPage !== undefined && pageNumber > paginatorInfo?.lastPage) {
    searchParams.set('page', String(paginatorInfo?.lastPage));
    setSearchParams(searchParams);
  }

  return {
    favourites,
    paginatorInfo,
  };
};
