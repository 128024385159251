/* eslint-disable react/jsx-props-no-spreading */
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { FormText } from 'react-bootstrap';
import styles from 'shared/ui/form/captcha/Captcha.module.scss';
import Reaptcha, { Props as ReaptchaProps } from 'reaptcha';
import { Control, Controller } from 'react-hook-form';
import { useFormErrorMessages } from 'shared/lib';
import type { FieldPath, FieldValues, ControllerProps } from 'react-hook-form/dist/types';
import cn from 'classnames';

interface CaptchaProps<
  TControl extends Control | never,
  TFields extends FieldValues = TControl extends Control<infer T> ? T : never,
  TName extends FieldPath<TFields> = FieldPath<TFields>,
> extends Partial<ReaptchaProps>, Pick<ControllerProps<TFields, TName>, 'name' | 'control' | 'rules'> {}

const CaptchaBase = <TControl extends Control<any> | never>(
  { name, control, rules, size, ...rest }: CaptchaProps<TControl>, ref: ForwardedRef<Reaptcha>,
) => {
  const [hasError, setHasError] = useState(false);
  const errorMessages = useFormErrorMessages();

  useEffect(() => {
    if (ref == null || typeof ref === 'function') {
      return () => {};
    }
    const { current: recaptcha } = ref;
    recaptcha?.render();

    return () => {
      if (
        recaptcha != null
        && recaptcha.state.ready
      ) {
        setHasError(false);
        recaptcha?.reset();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (control == null) {
    return (
      <div className={cn(styles.root, size === 'invisible' && styles.hidden)}>
        <Reaptcha
          {...rest}
          ref={ref}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
          onError={() => {
            setHasError(true);
            rest.onError?.();
          }}
        />
      </div>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <div className={cn(styles.root, size === 'invisible' && !hasError && styles.hidden)}>
          <Reaptcha
            {...rest}
            ref={ref}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onVerify={(response) => {
              rest.onVerify?.(response);
              if (response !== null) {
                onChange(response);
              }
            }}
            onExpire={() => {
              rest.onExpire?.();
              onChange(undefined);
            }}
            onError={() => {
              setHasError(true);
              rest.onError?.();
            }}
          />
          {error && (
            <FormText className="warning">{errorMessages[error.type]}</FormText>
          )}
        </div>
      )}
    />
  );
};

export const Captcha = forwardRef(CaptchaBase);
