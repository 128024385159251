import { CallbackForm } from 'features/callback';
import styles from './CooperationPage.module.scss';

export const CooperationPage = () => (
  <div className={styles.root}>
    <CallbackForm
      title="Сотрудничество"
      subtitle="Приглашаем к сотрудничеству дизайн-студии и дизайнеров на выгодных условиях. Оставьте заявку и мы с вами свяжемся."
      type="Дизайнер интерьера"
    />
  </div>
);
