import { createContext } from 'react';
// TODO: Fix type
export type Steps = {
  step1?: any;
  step2?: any;
  step3?: any;
  step4?: any;
};

export type SetStep = {
  setStep: <T extends (1 | 2 | 3 | 4)>(step: T, value: Steps[`step${T}`]) => void;
};

type ContextValue =
  {
    steps: Steps;
  } & SetStep;

export const ChangeEmailContext = createContext<ContextValue>({ steps: {}, setStep: () => {} });
