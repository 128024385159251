/* eslint-disable react/jsx-props-no-spreading */
import { Button, Captcha, PhoneInput, SuccessPopup, Textarea, TextInput } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { phone, phoneTo } from 'shared/config/constants';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { useCallbackForm } from '../model';
import styles from './CallbackForm.module.scss';

interface CallbackFormProps {
  title: string;
  subtitle?: string;
  headerContent?: boolean;
  type: string;
  setPopupVisible?: Dispatch<SetStateAction<boolean>>
  setEnhancedSuccessPopup?: Dispatch<SetStateAction<boolean>>
  withProductLink?: boolean;
}

interface FormData {
  name: string;
  phone: string;
  comment: string;
  response: string;
}

export const CallbackForm = (
  {
    title,
    subtitle,
    headerContent,
    type,
    setEnhancedSuccessPopup,
    setPopupVisible,
    withProductLink,
  }: CallbackFormProps,
) => {
  const captchaRef = useRef<Reaptcha>(null);
  const [captcha, setCaptcha] = useState(nanoid());
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    resetField,
    control,
    setValue,
    reset,
  } = useForm<FormData>();
  const {
    onSubmit,
    isSuccessPopupVisible,
    setSuccessIsPopupVisible,
  } = useCallbackForm({
    type,
    setPopupVisible,
    setEnhancedSuccessPopup,
    withProductLink,
    captchaRef,
    resetField,
    setCaptcha,
    setSubmitDisabled,
    setValue,
    reset,
  });
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <>
      <section className={styles.root}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          {headerContent && (
            <div className={styles.headerContent}>
              <span className={styles.hint}>Можно позвонить</span>
              <Link to={phoneTo} className={styles.link}>{phone}</Link>
            </div>
          )}
        </div>
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={cn(styles.wrapper, styles.item)}>
            <TextInput
              id="name"
              label="Имя"
              required
              hasError={errors.name != null}
              className={styles.input}
              help={errors.name ? errors.name.message : undefined}
              placeholder="Как вас зовут?"
              {...register('name', { required: errorMessage.required })}
            />
            <PhoneInput
              id="phone"
              label="Телефон"
              value={watch('phone')}
              required
              hasError={errors.phone != null}
              help={errors.phone ? errors.phone.message : undefined}
              {...register('phone', { required: errorMessage.required, validate: validateContact })}
            />
          </div>
          <div className={styles.item}>
            <Textarea
              id="comment"
              label="Комментарий"
              placeholder="Ваше сообщение"
              maxLength={512}
              {...register('comment')}
            />
            <Button
              type="submit"
              className={styles.submit}
              disabled={submitDisabled}
            >
              Оставить заявку
            </Button>
            <Captcha
              ref={captchaRef}
              key={captcha}
              name="response"
              control={control}
              size="invisible"
              onVerify={() => queueMicrotask(handleSubmit(onSubmit))}
            />
          </div>
        </form>
      </section>
      <SuccessPopup
        isSuccessPopupVisible={isSuccessPopupVisible}
        setPopupVisible={() => {
          setPopupVisible?.(false);
          setSuccessIsPopupVisible(false);
        }}
      />
    </>
  );
};
