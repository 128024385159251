import { SubmitHandler, UseFormResetField, UseFormSetError } from 'react-hook-form';
import { useUser } from 'entities/user';
import { MultistepPagination } from 'shared/lib';
import { Dispatch, RefObject, SetStateAction, useContext } from 'react';
import { useChangeEmailWithoutPasswordStep1Mutation } from 'shared/api/profile/email';
import { useLazyIsUserExistsQuery } from 'shared/api/user';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';
import { ChangeEmailContext } from './ChangeEmailContext';

interface FormData {
  email: string;
  response: string;
}

interface UseEmailFormProps {
  pagination: MultistepPagination;
  captchaRef: RefObject<Reaptcha>;
  resetField: UseFormResetField<FormData>;
  setCaptcha: Dispatch<SetStateAction<string>>;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  setError: UseFormSetError<FormData>;
}

export const useEmailForm = ({
  pagination,
  captchaRef,
  resetField,
  setCaptcha,
  setSubmitDisabled,
  setError,
}: UseEmailFormProps) => {
  const { user } = useUser();
  const { setStep } = useContext(ChangeEmailContext);
  const [changeEmailWithoutPassword] = useChangeEmailWithoutPasswordStep1Mutation();
  const [isUserExists] = useLazyIsUserExistsQuery();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setSubmitDisabled(true);
    if (data.response == null) {
      captchaRef.current?.execute();
      return;
    }
    const input = { new_email: data.email, response: data.response };
    const userInput = { email: data.email };
    if (!user?.email && !user?.lastPasswordResetAt) {
      isUserExists({ input: userInput })
        .then(({ data: dataUser }: any) => {
          if (dataUser?.IsUserExists) {
            setError('email', { type: '', message: 'Пользователь с такой почтой уже существует' });
            setSubmitDisabled(false);
          } else {
            changeEmailWithoutPassword({ input })
              .unwrap()
              .then(() => {
                setStep(2, input);
                pagination.goTo(2);
              })
              .catch((e) => setError('email', { type: '', message: e.message }))
              .finally(() => {
                setCaptcha(nanoid());
                resetField('response');
                setSubmitDisabled(false);
              });
          }
        });
    }
    if (!user?.lastPasswordResetAt && user?.email) {
      isUserExists({ input: userInput })
        .then(({ data: dataUser }: any) => {
          if (dataUser?.IsUserExists) {
            setError('email', { type: '', message: 'Пользователь с такой почтой уже существует' });
            setSubmitDisabled(false);
          } else {
            changeEmailWithoutPassword({ input })
              .then(() => {
                setStep(1, data);
                pagination.goTo(1);
              })
              .catch((e) => setError('email', { type: '', message: e.message }))
              .finally(() => {
                setCaptcha(nanoid());
                resetField('response');
                setSubmitDisabled(false);
              });
          }
        });
    }
    if (user?.lastPasswordResetAt) {
      isUserExists({ input: userInput })
        .then(({ data: dataUser }: any) => {
          if (dataUser?.IsUserExists) {
            setError('email', { type: '', message: 'Пользователь с такой почтой уже существует' });
            setSubmitDisabled(false);
          } else {
            setStep(3, data);
            pagination.goTo(3);
          }
        });
    }
  };
  return {
    onSubmit,
    defaultValue: user?.email,
  };
};
