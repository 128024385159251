/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ConfirmNewPhoneMutationVariables = Types.Exact<{
  input: Types.ConfirmNewPhoneInput;
}>;


export type ConfirmNewPhoneMutation = { __typename?: 'Mutation', ConfirmNewPhone: string };


export const ConfirmNewPhoneDocument = `
    mutation ConfirmNewPhone($input: ConfirmNewPhoneInput!) {
  ConfirmNewPhone(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ConfirmNewPhone: build.mutation<ConfirmNewPhoneMutation, ConfirmNewPhoneMutationVariables>({
      query: (variables) => ({ document: ConfirmNewPhoneDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useConfirmNewPhoneMutation } = injectedRtkApi;

