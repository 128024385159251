import { invalidateUserTags, TAG_USER } from '../user';
import { api as loginApi } from './login.csr.generated';
import { TAG_FAVOURITES } from '../favourites/tags';
import { TAG_CART } from '../cart';
import { TAG_HEADER } from '../header';

export { useLoginMutation } from './login.csr.generated';

const api = loginApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    Login: {
      invalidatesTags: [],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { invalidateTags } = api.util;
        await invalidateUserTags({ dispatch, queryFulfilled, invalidateTags });
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(loginApi.util.invalidateTags(
              [
                TAG_FAVOURITES as never,
                TAG_CART as never,
                TAG_HEADER as never,
              ],
            ));
          }
        } catch { /* empty */ }
      },
    },
  },
});
