import { CartFragment } from 'shared/api/cart';
import { NotificationContextValue } from './NotificationContext';

type UseUpdateListOptions<T> = {
  setData: NotificationContextValue['setData'],
  // TODO: Fix any type
  convert: (response: T) => CartFragment | undefined | any;
  actionType?: 'cart' | 'favorite',
};
export function updateList<T>(
  promise: Promise<T>,
  { setData, convert, actionType }: UseUpdateListOptions<T>,
): Promise<boolean> {
  return promise
    .then((response) => {
      const data = convert(response);
      if (Array.isArray(data?.products) && data?.products != null && data.products.length > 0) {
        const {
          id,
          name,
          attribute_rows: attributeRows,
          productFiles,
        } = data.products.at(-1)!;
        const product = {
          id,
          name,
          attributeRows,
          productFiles,
        };
        setData({ product, actionType });
      } else {
        const {
          id,
          name,
          attribute_rows: attributeRows,
          productFiles,
        } = data.product;
        const product = {
          id,
          name,
          attributeRows,
          productFiles,
        };
        setData({ product, actionType });
      }
      return true;
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
}
