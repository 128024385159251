/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type FavouritesChangeProductMutationVariables = Types.Exact<{
  input: Types.CartProductChangeInput;
}>;


export type FavouritesChangeProductMutation = { __typename?: 'Mutation', FavouritesChangeProduct?: { __typename?: 'FavouritesOperationResponse', product: { __typename?: 'Cartproduct', id: string, name: string, slug: string, amount?: number, price: number, attribute_rows?: Array<string>, category?: { __typename?: 'Category', slug: string }, productValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, slug?: string, vars?: string, value: string } }>, productVariantValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, vars?: string, slug?: string, value: string } }>, productFiles?: Array<{ __typename?: 'ProductFiles', id: string, file_path: string, fileType?: { __typename?: 'ProductFileType', name: string, id: string } }> } } };


export const FavouritesChangeProductDocument = `
    mutation FavouritesChangeProduct($input: CartProductChangeInput!) {
  FavouritesChangeProduct(input: $input) {
    product {
      id
      name
      slug
      category {
        slug
      }
      amount
      price
      attribute_rows
      productValues {
        id
        attribute_id
        attribute {
          id
          parent_attribute_id
          slug
          title
        }
        attributeValue {
          id
          parent_attribute_value_id
          slug
          vars
          value
        }
      }
      productVariantValues {
        id
        attribute_id
        attribute {
          id
          parent_attribute_id
          slug
          title
        }
        attributeValue {
          id
          parent_attribute_value_id
          vars
          slug
          value
        }
      }
      productFiles {
        id
        file_path
        fileType {
          name
          id
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    FavouritesChangeProduct: build.mutation<FavouritesChangeProductMutation, FavouritesChangeProductMutationVariables>({
      query: (variables) => ({ document: FavouritesChangeProductDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFavouritesChangeProductMutation } = injectedRtkApi;

