import { api as getCartAndFavouritesApi } from './getCartAndFavouritesCount.csr.generated';
import { TAG_HEADER } from './tags';

export { useGetCartAndFavouritesCountQuery } from './getCartAndFavouritesCount.csr.generated';
export { TAG_HEADER } from './tags';

getCartAndFavouritesApi.enhanceEndpoints({
  addTagTypes: [TAG_HEADER],
  endpoints: { getCartAndFavouritesCount: { providesTags: [TAG_HEADER] } },
});
