import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { LogoIcon, MainMenu, UserIcon } from 'shared/ui';
import { CartLink } from 'entities/cart';
import { useMenuQuery } from 'shared/api/menu';
import { menuIds } from 'entities/menu';
import { Paths, phone, phoneTo } from 'shared/config';
import { FavouritesLink } from 'entities/favourites';
import { HeaderMobile } from './HeaderMobile';
import styles from './Header.module.scss';
import { useGetCartAndFavouritesCountQuery } from '../../../shared/api/header';

interface HeaderProps {}

export const Header = forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
  const query = useMenuQuery({ input: { id: [menuIds.header] } });
  const leftMenuItemsData = useMemo(() => query.currentData?.Menu, [query.currentData]);
  const leftMenuItems = leftMenuItemsData && leftMenuItemsData?.[0].items;
  const favouriteInput = {
    first: 1,
    page: 1,
  };
  const cartAndFavouritesQuery = useGetCartAndFavouritesCountQuery({ input: favouriteInput });
  const favouritesCount = useMemo(
    () => cartAndFavouritesQuery.currentData?.FavouritesShow?.products?.paginatorInfo?.total,
    [cartAndFavouritesQuery.currentData],
  );
  const cartCount = useMemo(
    () => cartAndFavouritesQuery.currentData?.Cart?.count,
    [cartAndFavouritesQuery.currentData],
  );
  const rightMenuItems = [
    {
      link: Paths.Favourites,
      text: 'Избранное',
      id: '1',
      parent_id: '1',
      icon:
  <FavouritesLink
    count={favouritesCount}
    isFulfilled={!cartAndFavouritesQuery.isLoading}
  />,
    },
    {
      link: Paths.Cart,
      text: 'Корзина',
      id: '2',
      parent_id: '1',
      icon:
  <CartLink
    count={cartCount}
    isFulfilled={!cartAndFavouritesQuery.isLoading}
  />,
    },
    {
      link: Paths.Profile,
      text: 'Профиль',
      id: '3',
      parent_id: '1',
      icon: <UserIcon />,
    },
  ];
  return (
    <header className={styles.root} ref={ref}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={cn(styles.main)}>
            <Link to={Paths.Root}>
              <LogoIcon />
            </Link>
            {
              leftMenuItems && <MainMenu items={leftMenuItems} />
            }
          </div>
          <div className={styles.actions}>
            <Link to={phoneTo}>{phone}</Link>
            <MainMenu items={rightMenuItems} />
          </div>
        </div>
      </div>
      <HeaderMobile />
    </header>
  );
});
