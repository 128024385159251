import { Button } from 'shared/ui';
import { Paths, socials } from 'shared/config';
import { OrderStatusValues } from 'entities/order-history/types';
import { OrderStatus } from 'shared/api';
import styles from './OrderCard.module.scss';

interface OrderActionsProps {
  status: OrderStatus;
  paymentLink?: string;
}
export const OrderActions = ({ status, paymentLink }: OrderActionsProps) => (
  <>
    <Button
      variant="secondary"
      className={styles.btn}
      href={Paths.Contacts}
    >
      Нужна помощь?
    </Button>
    {
      status.title === OrderStatusValues.InProgress && (
        <a href={socials.Cdek} target="_blank" rel="noreferrer">
          <Button className={styles.btn}>Отследить</Button>
        </a>
      )
    }
    {
      status.title === OrderStatusValues.AwaitingPayment && (
        <a href={paymentLink} target="_blank" rel="noreferrer">
          <Button className={styles.btn}>Оплатить</Button>
        </a>
      )
    }
  </>
);
