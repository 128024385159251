import { useFavouritesProductToCartMutation } from 'shared/api/favourites';

export const useAddFavoriteToCart = (productId: string) => {
  const [addFavoriteToCart, result] = useFavouritesProductToCartMutation();

  const addToCart = () => {
    addFavoriteToCart({ id: productId });
  };

  return {
    addToCart,
    inProcess: result.isLoading,
  };
};
