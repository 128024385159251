/* eslint-disable react/jsx-props-no-spreading */
import { FormContainer } from 'entities/auth';
import { Button, ContactInput, LoadingHOC } from 'shared/ui';
import { useResetPasswordForm } from 'features/auth/reset-password/model';
import { formatPhone, useFormErrorMessages, useValidateContact } from 'shared/lib';
import { MultistepPageProps } from 'shared/lib/multistep/types';
import { useUser } from 'entities/user';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Captcha } from 'shared/ui/form';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import styles from './ResetPasswordForm.module.scss';

interface FormData {
  contact: string,
  response: string;
}
export const ResetPasswordForm = ({ pagination }: MultistepPageProps) => {
  const captchaRef = useRef<Reaptcha>(null);
  const [captcha, setCaptcha] = useState(nanoid());
  const {
    register,
    formState: { errors },
    setValue,
    control,
    handleSubmit,
    setError,
    resetField,
  } = useForm<FormData>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { user, isLoading } = useUser();
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  const contactValue = useMemo(() => user?.phone || user?.email || '', [user]);
  useEffect(() => {
    if (user && (user.phone || user.email)) {
      setValue('contact', formatPhone(user.phone) as string || user.email as string);
    }
  }, [setValue, user]);
  const title = useMemo(() => {
    if (user) {
      return user.lastPasswordResetAt ? 'Восстановление пароля' : 'Установить пароль';
    }
    return 'Восстановление пароля';
  }, [user]);
  const onSubmit = useResetPasswordForm(
    { pagination, captchaRef, resetField, setCaptcha, setSubmitDisabled, setError },
  );
  return (
    <LoadingHOC isLoading={isLoading}>
      <FormContainer
        id="reset-password"
        title={title}
        description="Введите почту или телефон, мы отправим туда код подтверждения"
        onSubmit={handleSubmit(onSubmit)}
        className={styles.root}
      >
        <ContactInput
          id="contact"
          label="Почта или номер телефона"
          position="center"
          hasError={errors.contact != null}
          help={errors.contact ? errors.contact.message : undefined}
          className={styles.contact}
          defaultValue={contactValue}
          {...register('contact', { required: errorMessage.required, validate: validateContact })}
        />
        <Captcha
          ref={captchaRef}
          key={captcha}
          name="response"
          control={control}
          size="invisible"
          onVerify={() => queueMicrotask(handleSubmit(onSubmit))}
        />
        <Button
          form="reset-password"
          type="submit"
          className={styles.submit}
          disabled={submitDisabled}
        >
          Получить код
        </Button>
      </FormContainer>
    </LoadingHOC>
  );
};
