import { TAG_USER } from 'shared/api/user';
import { api as confirmChangeEmailWithPasswordApi } from './ConfirmChangeEmailWithPassword.csr.generated';
import { api as confirmNewEmailApi } from './ConfirmNewEmail.csr.generated';

export { useChangeEmailWithPasswordMutation } from './ChangeEmailWithPassword.csr.generated';
export { useConfirmChangeEmailWithPasswordMutation } from './ConfirmChangeEmailWithPassword.csr.generated';
export { useChangeEmailWithoutPasswordStep1Mutation } from './ChangeEmailWithoutPasswordStep1.csr.generated';
export { useConfirmOldEmailAndSendNewCodeMutation } from './ConfirmOldEmailAndSendNewCode.csr.generated';
export { useConfirmNewEmailMutation } from './ConfirmNewEmail.csr.generated';

confirmChangeEmailWithPasswordApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    ConfirmChangeEmailWithPassword: {
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(confirmChangeEmailWithPasswordApi.util.invalidateTags([TAG_USER as never]));
          }
        } catch { /* empty */ }
      },
    },
  },
});

confirmNewEmailApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    ConfirmNewEmail: {
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(confirmNewEmailApi.util.invalidateTags([TAG_USER as never]));
          }
        } catch { /* empty */ }
      },
    },
  },
});
