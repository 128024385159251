/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ChangeEmailWithPasswordMutationVariables = Types.Exact<{
  input: Types.ChangeEmailWithPasswordInput;
}>;


export type ChangeEmailWithPasswordMutation = { __typename?: 'Mutation', ChangeEmailWithPassword: string };


export const ChangeEmailWithPasswordDocument = `
    mutation ChangeEmailWithPassword($input: ChangeEmailWithPasswordInput!) {
  ChangeEmailWithPassword(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ChangeEmailWithPassword: build.mutation<ChangeEmailWithPasswordMutation, ChangeEmailWithPasswordMutationVariables>({
      query: (variables) => ({ document: ChangeEmailWithPasswordDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeEmailWithPasswordMutation } = injectedRtkApi;

