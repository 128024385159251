import { Button } from 'shared/ui/button';
import { Dispatch, SetStateAction } from 'react';
import styles from './Popup.module.scss';
import { Popup } from './Popup';

interface SuccessPopupProps {
  isSuccessPopupVisible: boolean;
  setPopupVisible: Dispatch<SetStateAction<boolean>>
}
export const SuccessPopup = ({ isSuccessPopupVisible, setPopupVisible }: SuccessPopupProps) => (
  <Popup
    isPopupVisible={isSuccessPopupVisible}
    setPopupVisible={setPopupVisible}
    size="xl"
  >
    <h2 className={styles.successTitle}>Заявка отправлена!</h2>
    <p className={styles.successSubtitle}>Мы скоро свяжемся с вами</p>
    <Button
      onClick={() => setPopupVisible(false)}
      className={styles.successBtn}
    >
      Закрыть
    </Button>
  </Popup>
);
