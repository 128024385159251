import { Container } from 'react-bootstrap';
import cn from 'classnames';
import { useUser } from 'entities/user';
import { ProfileMenu } from 'entities/profile/menu';
import { Button, LoadingHOC } from 'shared/ui';
import { Paths } from 'shared/config';
import { useMemo } from 'react';
import { useCartQuery } from 'shared/api/cart';
import { Cartproduct } from 'shared/api';
import { ProductRow } from 'entities/product/row';
import { RemoveFromFavourites, EditFavoriteItem, AddFavoriteToCart } from 'features/favourites';
import { Pagination } from 'shared/ui/pagination';
import styles from './FavouritesPage.module.scss';
import { useGetFavourites } from '../model';

export const FavouritesPage = () => {
  const { user } = useUser();
  const query = useCartQuery();
  const cart = useMemo(() => query.currentData?.Cart, [query.currentData]);
  const cartProductIds = useMemo(() => cart?.products?.map(
    (product: Cartproduct) => product.id,
  ) || [], [cart]);
  const { favourites, paginatorInfo } = useGetFavourites();
  return (
    <div className={styles.root}>
      <Container>
        <div className={cn(styles.wrapper, !user && styles.center)}>
          { user && <ProfileMenu /> }
          <LoadingHOC isLoading={!favourites} className={styles.loader}>
            <div className={styles.content}>
              <h1 className={styles.title}>Избранное</h1>
              {
              favourites?.length ? (
                <div className={styles.cards}>
                  {
                    favourites?.map((item: Cartproduct) => {
                      const isInCart = cartProductIds.includes(item.id);
                      return (
                        <ProductRow
                          key={item.id}
                          product={item}
                          actions={[
                            <RemoveFromFavourites productId={item.id} key="remove" />,
                            !isInCart && <EditFavoriteItem product={item} key="edit" />]}
                          addToCartAction={isInCart
                            ? <span className={styles.hint}>В корзине</span>
                            : <AddFavoriteToCart text="В корзину" productId={item.id} />}
                        />
                      );
                    })
                  }
                </div>
              ) : (
                <div className="empty">
                  <h3 className="info">В избранном ничего нет</h3>
                  <Button type="button" href={Paths.Catalog} className="button">
                    В каталог
                  </Button>
                </div>
              )
            }
              {
              paginatorInfo && (
                <div className={styles.pagination}>
                  <Pagination
                    page={paginatorInfo.currentPage ?? 1}
                    total={paginatorInfo.lastPage ?? 1}
                    href=""
                  />
                </div>
              )
            }
            </div>
          </LoadingHOC>
        </div>
      </Container>
    </div>
  );
};
