import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Paths } from 'shared/config';
import {
  useChangeEmailWithPasswordMutation,
  useConfirmChangeEmailWithPasswordMutation,
  useConfirmNewEmailMutation, useConfirmOldEmailAndSendNewCodeMutation,
} from 'shared/api/profile/email';
import { useUser } from 'entities/user';
import { ChangeEmailContext } from './ChangeEmailContext';

interface FormData {
  confirmCode: string;
}
export const useConfirmCodeForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>();
  const navigate = useNavigate();
  const { user } = useUser();
  const { steps: { step1, step2 } } = useContext(ChangeEmailContext);
  const [confirmEmailWithPassword] = useConfirmChangeEmailWithPasswordMutation();
  const [confirmEmailWithoutPassword] = useConfirmNewEmailMutation();
  const [confirmOldEmail] = useConfirmOldEmailAndSendNewCodeMutation();
  const [changeEmailWithPassword] = useChangeEmailWithPasswordMutation();
  const setResendCode = () => {
    if (user?.lastPasswordResetAt) {
      const input = {
        new_email: step1?.email ?? step2?.new_email,
        password: step2.password,
      };
      changeEmailWithPassword({ input });
    }
    if (!user?.lastPasswordResetAt) {
      const input = {
        new_email: step1?.email ?? step2?.new_email,
        code: step2.code,
      };
      confirmOldEmail({ input });
    }
  };
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input = {
      new_email: step1?.email ?? step2?.new_email,
      code: data.confirmCode,
    };
    if (user?.lastPasswordResetAt) {
      confirmEmailWithPassword({ input })
        .unwrap()
        .then(() => {
          navigate(Paths.Profile);
        })
        .catch((error) => {
          setError('confirmCode', { type: '', message: error.message });
        });
    }
    if (!user?.lastPasswordResetAt) {
      confirmEmailWithoutPassword({ input })
        .unwrap()
        .then(() => {
          navigate(Paths.Profile);
        })
        .catch((error) => {
          reset();
          setError('confirmCode', { type: '', message: error.message });
        });
    }
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    setError,
    reset,
    clearErrors,
    email: step1?.email ?? step2?.new_email,
    setResendCode,
  };
};
