export function groupBy<T, K extends keyof any>(
  array: T[],
  getParameter: (item: T) => K | undefined | null,
) {
  const map = {} as Record<K, T[]>;

  array.forEach((item) => {
    const parameter = getParameter(item);
    if (parameter == null) {
      return;
    }
    if (map[parameter] == null) {
      map[parameter as K] = [];
    }
    map[parameter as K].push(item);
  });

  return map;
}
