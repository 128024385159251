export const delivery = {
  title: 'Доставка и оплата',
  content: '<div>\n'
    + '<p>Для приобретения товара на сайте достаточно добавить его в Корзину и оформить заказ. Наш менеджер свяжется с вами и завершит процесс оформления.</p>'
    + '<p>После подтверждения заказа в Личном кабинете вы сможете его оплатить. Оплата происходит через ПАО «СБЕРБАНК»  с использованием банковских карт следующих платёжных систем:</p>'
    + '<p>&mdash; МИР</p>'
    + '<p>&mdash; VISA International</p>'
    + '<p>&mdash; Mastercard Worldwide</p>'
    + '<p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО «СБЕРБАНК». Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. </p>'
    + '<p>В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept для проведения платежа также может потребоваться ввод специального пароля</p>'
    + '<p>Доставка и сборка в пределах города Красноярска осуществляется бесплатно. Заказы в другие города направляем компанией СДЭК. Возможна доставка до ПВЗ и курьером до двери. Расчет стоимости доставки ведется по тарифам ТК «СДЭК»</p>'
    + '<p>Перед тем, как отправить заказ транспортной компанией мы упаковываем товар, ставим дополнительную жесткую обрешетку (деревянный каркас). Такая упаковка защищает от несанкционированного доступа и повреждений.</p>'
    + '</div>',
};
