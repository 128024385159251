/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ChangePasswordMutationVariables = Types.Exact<{
  input: Types.ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', ChangePassword: { __typename?: 'AuthResponse', token?: string, tokenType?: string, expiresIn?: number } };


export const ChangePasswordDocument = `
    mutation ChangePassword($input: ChangePasswordInput!) {
  ChangePassword(input: $input) {
    token
    tokenType: token_type
    expiresIn: expires_in
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ChangePassword: build.mutation<ChangePasswordMutation, ChangePasswordMutationVariables>({
      query: (variables) => ({ document: ChangePasswordDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangePasswordMutation } = injectedRtkApi;

