/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type FavouritesDeleteProductMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type FavouritesDeleteProductMutation = { __typename?: 'Mutation', FavouritesDeleteProduct?: number };


export const FavouritesDeleteProductDocument = `
    mutation FavouritesDeleteProduct($id: ID!) {
  FavouritesDeleteProduct(id: $id)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    FavouritesDeleteProduct: build.mutation<FavouritesDeleteProductMutation, FavouritesDeleteProductMutationVariables>({
      query: (variables) => ({ document: FavouritesDeleteProductDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFavouritesDeleteProductMutation } = injectedRtkApi;

