import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ResetPasswordContext,
  CodeForm,
  ResetPasswordForm,
  useResetPasswordContext,
  PasswordForm,
} from 'features/auth/reset-password';
import { Multistep } from 'shared/lib';
import { Paths } from 'shared/config';
import { useUser } from 'entities/user';
import { Container } from 'react-bootstrap';
import styles from './ResetPasswordPage.module.scss';

export const ResetPasswordPage = () => {
  const { user, isLoading } = useUser();
  const stepState = useState(0);
  const resetPasswordContextValue = useResetPasswordContext();
  const [step] = stepState;

  return (
    <Container>
      <section className={styles.root}>
        <ResetPasswordContext.Provider value={resetPasswordContextValue}>
          <Multistep
            state={stepState}
            steps={[
              ResetPasswordForm,
              CodeForm,
              PasswordForm,
            ]}
          />
        </ResetPasswordContext.Provider>
      </section>
      {step === 0 && !user && !isLoading && (
        <div className="link-container">
          <Link to={Paths.LoginByPassword} className="link">Вернуться ко входу</Link>
        </div>
      )}
    </Container>
  );
};
