import { Paths } from 'shared/config';
import { HomePage } from 'pages/home';
import { CategoriesPage } from 'pages/categories';
import { CartPage } from 'pages/cart';
import { LoginByPasswordPage } from 'pages/login-by-password';
import { LoginByCodePage } from 'pages/login-by-code';
import { ResetPasswordPage } from 'pages/reset-password';
import { ProfilePage } from 'pages/profile';
import { CatalogPage } from 'pages/catalog';
import { ProfileChangeNamePage } from 'pages/profile-change-name';
import { ProfileChangeEmailPage } from 'pages/profile-change-email';
import { ProfileChangePhonePage } from 'pages/profile-change-phone';
import { FavouritesPage } from 'pages/favourites';
import { ProfileAddressPage } from 'pages/profile-address';
import { ProfileChangeAddressPage } from 'pages/profile-change-address';
import { ProfilePasswordPage } from 'pages/profile-password';
import { ProfileChangePasswordPage } from 'pages/profile-change-password';
import { OrderHistoryPage } from 'pages/order-history';
import { OrderCardPage } from 'pages/order-card';
import { PortfolioPage } from 'pages/portfolio';
import { CooperationPage } from 'pages/cooperation';
import { ContactsPage } from 'pages/contacts';
import { AboutPage } from 'pages/about';
import { PdfCatalogPage, ThreeDMaxCatalogPage } from 'pages/catalogs';
import { CheckoutPage } from 'pages/checkout';
import { CheckoutSuccessPage } from 'pages/checkout-success';
import { EnhancedProductPage } from 'pages/product/ui/ProductPage';
import { PolicyPage } from 'pages/policy';
import { OfferPage } from 'pages/policy/ui';
import { DeliveryPage } from 'pages/delivery';

export const publicRoutes = [
  {
    path: Paths.Root,
    element: <HomePage />,
  },
  {
    path: Paths.ResetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: Paths.Catalog,
    element: <CategoriesPage />,
  },
  {
    path: Paths.Products,
    element: <CatalogPage />,
  },
  {
    path: Paths.Cart,
    element: <CartPage />,
  },
  {
    path: Paths.Product,
    element: <EnhancedProductPage />,
  },
  // TODO: Fix this route
  {
    path: Paths.LoginByCode,
    element: <LoginByCodePage />,
  },
  {
    path: Paths.Favourites,
    element: <FavouritesPage />,
  },
  {
    path: Paths.Portfolio,
    element: <PortfolioPage />,
  },
  {
    path: Paths.Cooperation,
    element: <CooperationPage />,
  },
  {
    path: Paths.Contacts,
    element: <ContactsPage />,
  },
  {
    path: Paths.About,
    element: <AboutPage />,
  },
  {
    path: Paths.PdfCatalog,
    element: <PdfCatalogPage />,
  },
  {
    path: Paths.ThreeDMaxCatalog,
    element: <ThreeDMaxCatalogPage />,
  },
  {
    path: Paths.Checkout,
    element: <CheckoutPage />,
  },
  {
    path: Paths.CheckoutSuccess,
    element: <CheckoutSuccessPage />,
  },
  {
    path: Paths.Policy,
    element: <PolicyPage />,
  },
  {
    path: Paths.Offer,
    element: <OfferPage />,
  },
  {
    path: Paths.Delivery,
    element: <DeliveryPage />,
  },
];

export const authRoutes = [
  {
    path: Paths.LoginByPassword,
    element: <LoginByPasswordPage />,
  },
];

export const privateRoutes = [
  {
    path: Paths.Profile,
    element: <ProfilePage />,
  },
  {
    path: Paths.ProfileName,
    element: <ProfileChangeNamePage />,
  },
  {
    path: Paths.ProfileEmail,
    element: <ProfileChangeEmailPage />,
  },
  {
    path: Paths.ProfilePhone,
    element: <ProfileChangePhonePage />,
  },
  {
    path: Paths.ProfileAddress,
    element: <ProfileAddressPage />,
  },
  {
    path: Paths.ProfileAddressEdit,
    element: <ProfileChangeAddressPage />,
  },
  {
    path: Paths.ProfilePassword,
    element: <ProfilePasswordPage />,
  },
  {
    path: Paths.ProfileChangePassword,
    element: <ProfileChangePasswordPage />,
  },
  {
    path: Paths.ProfileHistory,
    element: <OrderHistoryPage />,
  },
  {
    path: Paths.OrderCard,
    element: <OrderCardPage />,
  },
];
