/* eslint-disable react/jsx-props-no-spreading */
import { Container } from 'react-bootstrap';
import { ProfileMenu } from 'entities/profile/menu';
import { OrderHistoryItem } from 'entities/order-history';
import { Button, LoadingHOC } from 'shared/ui';
import { Paths } from 'shared/config';
import { Pagination } from 'shared/ui/pagination';
import styles from './OrderHistoryPage.module.scss';
import { useGetOrderHistory } from '../model';

export const OrderHistoryPage = () => {
  const { history, paginatorInfo } = useGetOrderHistory();
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <ProfileMenu />
          <div className={styles.content}>
            <h1 className={styles.title}>История заказов</h1>
            <LoadingHOC isLoading={!history} className={styles.loader}>
              {
                history?.length ? (
                  history?.map((item) => (
                    <OrderHistoryItem key={item.id} {...item} />
                  ))
                ) : (
                  <div className="empty">
                    <h2 className="info">Заказов еще нет</h2>
                    <Button href={Paths.Catalog} className="button">В каталог</Button>
                  </div>
                )
              }
            </LoadingHOC>
          </div>
        </div>
        {
          paginatorInfo && (
            <div className={styles.pagination}>
              <Pagination
                page={paginatorInfo.currentPage ?? 1}
                total={paginatorInfo.lastPage ?? 1}
                href=""
              />
            </div>
          )
        }
      </Container>
    </div>
  );
};
