import { Button } from 'shared/ui';
import { Paths } from 'shared/config';
import { useUser } from 'entities/user';
import { formatDateDifference } from 'shared/lib';
import styles from './ProfilePassword.module.scss';

export const ProfilePassword = () => {
  const { user } = useUser();
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Пароль</h1>
      <span className={styles.subtitle}>
        {
          user?.lastPasswordResetAt
            ? `Изменен ${formatDateDifference(user.lastPasswordResetAt)} назад`
            : 'Вы можете установить пароль и выполнять вход по нему'
        }
      </span>
      <Button
        className={styles.btn}
        href={
          user?.lastPasswordResetAt
            ? Paths.ProfileChangePassword
            : Paths.ResetPassword
        }
      >
        {
          user?.lastPasswordResetAt
            ? 'Изменить пароль'
            : 'Установить пароль'
        }
      </Button>
    </div>
  );
};
