import { LoadingHOC, Slider } from 'shared/ui';
import { useMemo } from 'react';
import { useConstantQuery } from 'shared/api/constants';
import { content } from 'shared/config';
import styles from './PortfolioPage.module.scss';

export const PortfolioPage = () => {
  const constantQuery = useConstantQuery({ slug: content.porfolio });
  const slides = useMemo(
    () => constantQuery.currentData?.Constant.value.image_data,
    [constantQuery],
  );
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Портфолио</h1>
      <span className={styles.subtitle}>Вдохновение от наших дизайнеров</span>
      <LoadingHOC isLoading={constantQuery.isLoading}>
        <div>
          <Slider slides={slides} />
        </div>
      </LoadingHOC>
    </div>
  );
};
