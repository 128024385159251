/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type CartDecreaseAmountMutationVariables = Types.Exact<{
  input: Types.CartProductInput;
}>;


export type CartDecreaseAmountMutation = { __typename?: 'Mutation', CartDecreaseAmount?: { __typename?: 'CartFront', productsCount: number } };


export const CartDecreaseAmountDocument = `
    mutation CartDecreaseAmount($input: CartProductInput!) {
  CartDecreaseAmount(input: $input) {
    productsCount: products_count
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CartDecreaseAmount: build.mutation<CartDecreaseAmountMutation, CartDecreaseAmountMutationVariables>({
      query: (variables) => ({ document: CartDecreaseAmountDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCartDecreaseAmountMutation } = injectedRtkApi;

