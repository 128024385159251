import { TAG_FAVOURITES } from './tags';
import { TAG_CART } from '../cart';
import { TAG_HEADER } from '../header';
import { api as favouritesApi } from './getFavourites.csr.generated';
import { api as addToFavouritesApi } from './addToFavourites.csr.generated';
import { api as deleteFromFavouritesApi } from './deleteFromFavourites.csr.generated';
import { api as addFavouritesToCart } from './addFavouritesToCart.csr.generated';
import { api as changeFavouritesAttribute } from './changeFavouritesAttirubute.csr.generated';
import { TAG_PRODUCT } from '../product';

export { useFavouritesShowQuery } from './getFavourites.csr.generated';
export { useFavouritesAddProductMutation } from './addToFavourites.csr.generated';
export { useFavouritesDeleteProductMutation } from './deleteFromFavourites.csr.generated';
export { useFavouritesProductToCartMutation } from './addFavouritesToCart.csr.generated';
export { useFavouritesChangeProductMutation } from './changeFavouritesAttirubute.csr.generated';

favouritesApi.enhanceEndpoints({
  addTagTypes: [TAG_FAVOURITES],
  endpoints: { FavouritesShow: { providesTags: [TAG_FAVOURITES] } },
});

addToFavouritesApi.enhanceEndpoints({
  addTagTypes: [TAG_FAVOURITES, TAG_HEADER],
  endpoints: {
    FavouritesAddProduct: {
      invalidatesTags: [
        TAG_FAVOURITES,
        TAG_HEADER,
        TAG_PRODUCT as never,
      ],
    },
  },
});

deleteFromFavouritesApi.enhanceEndpoints({
  addTagTypes: [TAG_FAVOURITES, TAG_HEADER],
  endpoints: {
    FavouritesDeleteProduct: {
      invalidatesTags: [
        TAG_FAVOURITES,
        TAG_HEADER,
        TAG_PRODUCT as never,
      ],
    },
  },
});

addFavouritesToCart.enhanceEndpoints({
  addTagTypes: [TAG_CART, TAG_HEADER],
  endpoints: { FavouritesProductToCart: { invalidatesTags: [TAG_CART, TAG_HEADER] } },
});

changeFavouritesAttribute.enhanceEndpoints({
  addTagTypes: [TAG_FAVOURITES],
  endpoints: { FavouritesChangeProduct: { invalidatesTags: [TAG_FAVOURITES] } },
});
