import { FavoriteIcon } from 'shared/ui';
import cn from 'classnames';
import styles from './FavouritesLink.module.scss';

interface CartLinkProps {
  className?: string;
  isFulfilled?: boolean;
  count?: number;
}

export const FavouritesLink = ({ className, isFulfilled = true, count = 0 }: CartLinkProps) => (
  <div className={styles.root}>
    {(count > 0 && isFulfilled) && (
    <div className={styles.count}>{count}</div>
    )}
    <FavoriteIcon className={cn(styles.icon, className)} />
  </div>
);
