/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, Button, IconButton, PasswordInput } from 'shared/ui';
import { FormContainer } from 'features/profile/edit/data';
import { MultistepPageProps, useFormErrorMessages } from 'shared/lib';
import { Link } from 'react-router-dom';
import { Paths } from 'shared/config';
import styles from 'features/profile/edit/data/ui/PasswordConfirmationForm.module.scss';
import { usePasswordConfirmationForm } from '../model';

export const PasswordConfirmationForm = ({ pagination }: MultistepPageProps) => {
  const { register, onSubmit, errors } = usePasswordConfirmationForm(pagination);
  const errorMessage = useFormErrorMessages();
  return (
    <FormContainer
      title="Введите пароль"
      onSubmit={onSubmit}
      className={styles.root}
      description="Подтвердите, изменение паролем"
      rootClass
    >
      <IconButton className="stepBack" onClick={() => pagination.goTo(0)}>
        <ArrowBackIcon />
      </IconButton>
      <PasswordInput
        id="password"
        label="Пароль"
        hasError={errors.password != null}
        required
        className={styles.password}
        help={errors.password ? errors.password.message : undefined}
        {...register('password', { required: errorMessage.required })}
      />
      <Button type="submit" className={styles.submit}>Сохранить</Button>
      <Link to={Paths.ResetPassword} className="link">Забыли пароль?</Link>
    </FormContainer>
  );
};
