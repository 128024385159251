import { Link } from 'react-router-dom';
import { content, phoneTo } from 'shared/config';
import cn from 'classnames';
import { InstagramIcon, LoadingHOC, PinterestIcon, VkIcon } from 'shared/ui';
import { CallbackForm } from 'features/callback';
import { useEffect, useRef, useMemo } from 'react';
import { useConstantQuery } from 'shared/api/constants';
import styles from './ContactsPage.module.scss';

export const ContactsPage = () => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // eslint-disable-next-line global-require,import/no-extraneous-dependencies
    const DG = require('2gis-maps');
    const map = DG.map(mapContainerRef?.current?.id, {
      center: [56.055900, 92.889564],
      zoom: 17
      ,
    });
    DG.marker([56.055900, 92.889564]).addTo(map);
    DG.tileLayer('http://{s}.maps.api.2gis.ru/templates/2gis/{z}/{x}/{y}.png').addTo(map);

    return () => {
      map.remove();
    };
  }, []);
  const constantsQuery = useConstantQuery({ slug: content.contacts });
  const contacts = useMemo(() => constantsQuery.currentData?.Constant.value, [constantsQuery]);
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Контакты</h1>
      <div className={styles.wrapper}>
        <div className={styles.map}>
          <div id="map" ref={mapContainerRef} style={{ height: '230px', width: '350px' }} />
        </div>
        <LoadingHOC isLoading={constantsQuery.isLoading} className={styles.loader}>
          <div className={styles.info}>
            <div className={styles.item}>
              <span className={styles.property}>Телефон</span>
              <Link to={phoneTo} className={styles.value}>{contacts?.phone}</Link>
            </div>
            <div className={styles.item}>
              <span className={styles.property}>Адрес</span>
              <div className={styles.address}>
                <span className={styles.value}>{contacts?.address}</span>
                <span className={styles.time}>Время работы: 10:00 — 20:00</span>
              </div>
            </div>
            <div className={styles.item}>
              <span className={styles.property}>Соц. сети</span>
              <div className={styles.socials}>
                <Link
                  to={contacts?.pinterest ?? ''}
                  target="_blank"
                >
                  <PinterestIcon className={cn(styles.icon, styles.pinterest)} />
                </Link>
                <Link
                  to={contacts?.instagram ?? ''}
                  target="_blank"
                >
                  <InstagramIcon className={cn(styles.icon, styles.instagram)} />
                </Link>
                <Link
                  to={contacts?.vk ?? ''}
                  target="_blank"
                >
                  <VkIcon className={cn(styles.icon, styles.vk)} />
                </Link>
              </div>
            </div>
          </div>
        </LoadingHOC>
      </div>
      <CallbackForm title="Написать нам" type="Контакты" />
    </div>
  );
};
