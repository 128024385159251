/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { FormContainer } from 'features/profile/edit/data';
import { CodeInput, IconButton, ResendCode } from 'shared/ui';
import { timestamp, MultistepPageProps } from 'shared/lib';
import { ArrowBackIcon } from 'shared/ui/icons';
import styles from 'features/profile/edit/data/ui/CodeForm.module.scss';
import { useCodeForm } from '../model';

export const CodeForm = ({ pagination }: MultistepPageProps) => {
  const {
    onSubmit,
    setValue,
    errors,
    setResendCode,
    clearErrors,
    email,
  } = useCodeForm(pagination);
  const [codeRequestTime, setCodeRequestTime] = useState(timestamp());
  const onCodeResend = (requestTime: number) => {
    setCodeRequestTime(requestTime);
  };
  return (
    <FormContainer
      title="Подтверждение"
      id="change-email"
      onSubmit={onSubmit}
      className={styles.root}
      rootClass
      description={(
        <>
          <p className={styles.description}>
            Введите код или перейдите по ссылке, которые были отправлены на почту
            <span className={styles.contact}>{` ${email}`}</span>
          </p>
          <p className={styles.description}>
            Подтверждение нужно
            <br />
            в целях безопасности аккаунта
          </p>
        </>
)}
    >
      <IconButton className="stepBack" onClick={pagination.back}>
        <ArrowBackIcon />
      </IconButton>
      <CodeInput
        setCode={(code) => {
          clearErrors();
          setValue('code', code);
          onSubmit();
        }}
        hasError={errors.code != null}
        help={errors.code?.message}
      />
      <ResendCode
        startTime={codeRequestTime}
        onResend={onCodeResend}
        setResendCode={setResendCode}
      />
    </FormContainer>
  );
};
