import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'shared/config';
import { useChangePasswordMutation } from 'shared/api/profile';

interface FormData {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const usePasswordChangeForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setError,
  } = useForm<FormData>();
  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();
  const onSubmit:SubmitHandler<FormData> = (data) => {
    const input = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
      new_password_confirmation: data.confirmNewPassword,
    };
    changePassword({ input })
      .unwrap()
      .then(() => navigate(Paths.ProfilePassword))
      .catch((error) => {
        setError('oldPassword', { type: 'custom', message: error.message });
      });
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
    getValues,
  };
};
