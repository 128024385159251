import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './PaginationItem.module.scss';

interface PaginationPrevItemProps {
  href: string;
  isFirst: boolean;
}

export const PaginationPrevItem = ({ href, isFirst }: PaginationPrevItemProps) => (
  <Link to={href} className={cn(styles.item, styles.itemPrev, isFirst && styles.itemDisabled)}>
    &lt;
  </Link>
);
