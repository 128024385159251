import { CategoriesList } from 'features/categories';
import { Designer } from 'features/designer';
import { Container } from 'react-bootstrap';
import styles from './CategoriesPage.module.scss';

export const CategoriesPage = () => (
  <Container>
    <div className={styles.root}>
      <CategoriesList title="Каталог" fromCatalog />
      <Designer />
    </div>
  </Container>
);
