import { SubmitHandler, UseFormResetField, UseFormSetError } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { LoginUserInput } from 'shared/api';
import { Paths } from 'shared/config';
import { normalizePhone, regex } from 'shared/lib';
import { setAuthData, castAuthData } from 'entities/user';
import { useLoginMutation } from 'shared/api/login-by-password';
import { Dispatch, RefObject, SetStateAction } from 'react';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';

interface FormData {
  contact: string,
  password: string,
  response: string;
}

interface UseLoginFormProps {
  captchaRef: RefObject<Reaptcha>;
  resetField: UseFormResetField<FormData>;
  setCaptcha: Dispatch<SetStateAction<string>>;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  setError: UseFormSetError<FormData>;
}

export function useLoginByPasswordForm({
  captchaRef,
  resetField,
  setCaptcha,
  setSubmitDisabled,
  setError,
}: UseLoginFormProps) {
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setSubmitDisabled(true);
    if (data.response == null) {
      captchaRef.current?.execute();
      return;
    }
    const input: LoginUserInput = { password: data.password, response: data.response };
    if (regex.email.test(data.contact)) {
      input.email = data.contact;
    } else if (regex.phone.test(data.contact)) {
      input.phone = normalizePhone(data.contact);
    }
    login({ input })
      .unwrap()
      .then((res) => {
        const authData = castAuthData(res.Login);
        if (authData == null) {
          throw new Error();
        }
        dispatch(setAuthData(authData));
        navigate(Paths.Root);
      })
      .catch((error) => {
        setError('contact', { type: '', message: error.message });
        setError('password', { type: '', message: '' });
      })
      .finally(() => {
        setCaptcha(nanoid());
        resetField('response');
        setSubmitDisabled(false);
      });
  };

  return onSubmit;
}
