import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'entities/user';
import { useContext } from 'react';
import {
  useChangePhoneWithoutPasswordStep1Mutation,
  useConfirmOldPhoneAndSendNewCodeMutation,
} from 'shared/api/profile/phone';
import { MultistepPagination, normalizePhone } from 'shared/lib';
import { ChangePhoneContext } from './ChangePhoneContext';

interface FormData {
  code: string;
}
export const useCodeForm = (pagination: MultistepPagination) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>();
  const { user } = useUser();
  const { setStep, steps: { step1 } } = useContext(ChangePhoneContext);
  const [confirmOldPhone] = useConfirmOldPhoneAndSendNewCodeMutation();
  const [changePhoneWithoutPassword] = useChangePhoneWithoutPasswordStep1Mutation();
  const setResendCode = () => {
    const input = { new_phone: normalizePhone(step1.phone) };
    changePhoneWithoutPassword({ input });
  };
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input = {
      new_phone: normalizePhone(step1.phone),
      code: data.code,
    };
    confirmOldPhone({ input })
      .unwrap()
      .then(() => {
        setStep(2, data);
        pagination.goTo(2);
      })
      .catch((error) => {
        reset();
        setError('code', { type: '', message: error.message });
      });
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    setError,
    reset,
    clearErrors,
    phone: user?.phone || step1?.phone,
    setResendCode,
  };
};
