import { CloseIcon } from 'shared/ui';
import { useFavouritesDeleteProductMutation } from 'shared/api/favourites';
import styles from './Actions.module.scss';

interface RemoveFromFavouritesProps {
  productId: string;
}
export const RemoveFromFavourites = ({ productId }: RemoveFromFavouritesProps) => {
  const [removeFromFavourites] = useFavouritesDeleteProductMutation();
  const onClick = () => {
    removeFromFavourites({ id: productId });
  };
  return (
    <button type="button" onClick={onClick} className={styles.root} aria-label="remove"><CloseIcon className={styles.icon} /></button>
  );
};
