/* eslint-disable react/jsx-props-no-spreading */
import { Paths } from 'shared/config';
import { Link } from 'react-router-dom';
import { CallIcon, CatalogIcon, DesignerIcon, UserIcon } from 'shared/ui';
import { CartLink } from 'entities/cart';
import { DesignerForm } from 'features/designer';
import { useGetCartCountQuery } from 'shared/api/cart';
import { useMemo, useState } from 'react';
import styles from './FooterMobile.module.scss';

export const FooterMobile = () => {
  const query = useGetCartCountQuery();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const cartCount = useMemo(() => query.currentData?.Cart?.count, [query.currentData]);
  return (
    <div className={styles.root}>
      <Link to={Paths.Catalog} className={styles.item}>
        <CatalogIcon className={styles.icon} />
        <span>Каталог</span>
      </Link>
      <Link to={Paths.Contacts} className={styles.item}>
        <CallIcon className={styles.icon} />
        <span>Связаться</span>
      </Link>
      <Link to={Paths.Cart} className={styles.item}>
        <CartLink className={styles.icon} count={cartCount} />
        <span>Корзина</span>
      </Link>
      <button type="button" className={styles.item} onClick={() => setPopupVisible(true)}>
        <DesignerIcon className={styles.icon} />
        <span>Я дизайнер</span>
      </button>
      <Link to={Paths.Profile} className={styles.item}>
        <UserIcon className={styles.icon} />
        <span>Профиль</span>
      </Link>
      <DesignerForm isPopupVisible={isPopupVisible} setPopupVisible={setPopupVisible} />
    </div>
  );
};
