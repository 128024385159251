import { Button } from 'shared/ui';
import { ReactNode } from 'react';
import styles from './AddFavoriteToCart.module.scss';
import { useAddFavoriteToCart } from '../model';

interface AddToCartProps {
  text: ReactNode;
  productId: string;
  onClick?: () => void;
}
export const AddFavoriteToCart = ({ text, productId, onClick }: AddToCartProps) => {
  const { addToCart, inProcess } = useAddFavoriteToCart(productId);
  const handleClick = () => {
    addToCart();
    onClick?.();
  };
  return (
    <Button
      type="button"
      className={styles.btn}
      onClick={handleClick}
      disabled={inProcess}
    >
      {text}
    </Button>
  );
};
