/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, Button, Captcha, EmailInput, IconButton } from 'shared/ui';
import { FormContainer } from 'features/profile/edit/data';
import { MultistepPageProps, useFormErrorMessages } from 'shared/lib';
import { Paths } from 'shared/config';
import { Link } from 'react-router-dom';
import styles from 'features/profile/edit/data/ui/MainForms.module.scss';
import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';
import { useEmailForm } from '../model';

interface FormData {
  email: string;
  response: string;
}
export const EmailChangeForm = ({ pagination }: MultistepPageProps) => {
  const errorMessage = useFormErrorMessages();
  const captchaRef = useRef<Reaptcha>(null);
  const [captcha, setCaptcha] = useState(nanoid());
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    resetField,
    control,
  } = useForm<FormData>();
  const { onSubmit, defaultValue } = useEmailForm(
    { pagination, captchaRef, resetField, setCaptcha, setSubmitDisabled, setError },
  );
  // Explanation:
  // In Punycode, the Cyrillic alphabet has the string 'xn'
  // so, the string is checked for the presence of this substring,
  // since the Cyrillic alphabet is immediately converted to Punycode
  return (
    <FormContainer
      title={defaultValue ? 'Изменить почту' : 'Установить почту'}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.root}
      description="Новую почту нужно будет подтвердить"
      rootClass
    >
      <Link to={Paths.Profile}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <EmailInput
        id="email"
        label="Почта"
        placeholder="example@mail.ru"
        hasError={errors.email != null}
        className={styles.input}
        help={errors.email ? errors.email.message : undefined}
        defaultValue={defaultValue}
        {...register('email', {
          required: errorMessage.required,
          validate: (value: string) => !value.includes('xn') || 'Неверный формат почты',
        })}
      />
      <Button
        type="submit"
        className={styles.submit}
        disabled={submitDisabled}
      >
        Продолжить
      </Button>
      <Captcha
        ref={captchaRef}
        key={captcha}
        name="response"
        control={control}
        size="invisible"
        onVerify={() => queueMicrotask(handleSubmit(onSubmit))}
      />
    </FormContainer>
  );
};
