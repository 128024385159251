import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { content, phoneTo } from 'shared/config';
import { useMemo } from 'react';
import { useConstantQuery } from 'shared/api/constants';
import styles from './AboutPage.module.scss';

export const AboutPage = () => {
  const constantQuery = useConstantQuery({ slug: content.about });
  const about = useMemo(() => constantQuery.currentData?.Constant.value, [constantQuery]);
  return (
    <div className={styles.root}>
      <Container>
        <h1 className={styles.title}>О компании</h1>
        <div className={styles.wrapper}>
          <img src="/about/About.jpg" alt="" className={styles.img} />
          <div className={styles.text}>
            <p className={styles.name}>«Тим столы и комоды»</p>
            <p>
              Новый проект «Тим мебель» был создан по многочисленным просьбам дизайнеров интерьера
            </p>
            <p>
              Мы производственная компания,
              основанная в 2015 году, имеем хорошую
              оценку по мнению
              <Link to={about?.gis ?? ''} target="_blank">{' 2ГИС '}</Link>
              с отзывами клиентов
            </p>
            <p>
              На сегодняшний день производим
              мебель на заказ при помощи оборудования
              и собственного производства реализуем
              разные дизайнерские идеи
            </p>
            <p>Сможем подобрать индивидуальный подход к каждому</p>
            <p>
              Для дизайнеров и дизайн-студий мы предлагаем сотрудничество.
              По данному вопросу вы можете обратиться к нашему менеджеру
              <Link to={phoneTo}>{` ${about?.phone}`}</Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};
