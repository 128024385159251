import { EditCartIcon } from 'shared/ui';
import { Cartproduct, ProductAttributeValues } from 'shared/api';
import { useEffect, useState } from 'react';
import { processAttributes, transformAttributes, AttributeModal } from 'entities/product/attribute';
import { AttributesIds } from 'entities/product/attribute/model';
import { useFavouritesChangeProductMutation } from 'shared/api/favourites';
import styles from './Actions.module.scss';

interface EditFavoriteItemProps {
  product: Cartproduct;
}
export const EditFavoriteItem = ({ product }: EditFavoriteItemProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAttributeId, setSelectedAttributeId] = useState<string[]>(['initial']);
  const [
    selectedAttributes,
    setSelectedAttributes,
  ] = useState(processAttributes(product?.productValues ?? []));
  const attributesIds = transformAttributes({ attributes: selectedAttributes });
  const [changeAttribute] = useFavouritesChangeProductMutation();
  const onClick = () => {
    setIsModalVisible(true);
  };
  const updateCartAttribute = (id: string, valueId: string) => {
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [id]: valueId,
    }));
  };
  const clearAttributeValue = (attributeId: string) => {
    if (attributeId in selectedAttributes) {
      const { [attributeId]: removedValue, ...newAttributes } = selectedAttributes;
      setSelectedAttributes(newAttributes);
    }
  };
  useEffect(() => {
    if (
      JSON.stringify(selectedAttributes)
      !== JSON.stringify(processAttributes(product?.productValues ?? []))) {
      const input = { product_id: product.id, attribute_values: attributesIds };
      changeAttribute({ input });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttributes]);
  const updateAttributes = (attribute: ProductAttributeValues) => {
    if (attribute.attribute_id === AttributesIds.Material
      && !attributesIds?.includes(attribute!.attributeValue!.id)) {
      clearAttributeValue(AttributesIds.TableColor);
    }
    if (attribute.attribute_id === AttributesIds.Form
      && !attributesIds?.includes(attribute!.attributeValue!.id)) {
      clearAttributeValue(AttributesIds.Size);
    }
    updateCartAttribute(attribute.attribute_id, attribute!.attributeValue!.id);
    setIsModalVisible(false);
  };
  const currentAttributes = product.productVariantValues?.filter(
    (item) => item.attribute_id === selectedAttributeId[1],
  );
  useEffect(() => {
    if (!isModalVisible) {
      setTimeout(() => {
        setSelectedAttributeId(['initial']);
      }, 300);
    }
  }, [isModalVisible]);
  return (
    <>
      <button type="button" onClick={onClick} className={styles.root}>
        <EditCartIcon className={styles.icon} />
      </button>
      <AttributeModal
        isPopupVisible={isModalVisible}
        setPopupVisible={setIsModalVisible}
        updateAttributes={updateAttributes}
        selectedAttributeId={selectedAttributeId}
        setSelectedAttributeId={setSelectedAttributeId}
        currentAttributes={currentAttributes}
        productValues={product.productValues}
        steps={2}
        formId={selectedAttributes[AttributesIds.Form]}
        materialId={selectedAttributes[AttributesIds.Material]}
      />
    </>
  );
};
