import { invalidateUserTags, TAG_USER } from '../user';
import { api as resetPasswordApi } from './resetPasswordStep3.csr.generated';
import { TAG_FAVOURITES } from '../favourites/tags';
import { TAG_CART } from '../cart';
import { TAG_HEADER } from '../header';

export { useResetPasswordStep1Mutation } from './resetPasswordStep1.csr.generated';
export { useResetPasswordStep2Mutation } from './resetPasswordStep2.csr.generated';
export { useResetPasswordStep3Mutation } from './resetPasswordStep3.csr.generated';

const api = resetPasswordApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    ResetPasswordStep3: {
      invalidatesTags: [],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { invalidateTags } = api.util;
        await invalidateUserTags({ dispatch, queryFulfilled, invalidateTags });
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(
              resetPasswordApi.util.invalidateTags(
                [
                  TAG_FAVOURITES as never,
                  TAG_CART as never,
                  TAG_HEADER as never,
                ],
              ),
            );
          }
        } catch { /* empty */ }
      },
    },
  },
});
