/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ConstantQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type ConstantQuery = { __typename?: 'Query', Constant: { __typename?: 'Constant', id: string, name: string, slug: string, description?: string, value: { __typename?: 'ConstantValue', phone?: string, address?: string, productSlugs?: string, vk?: string, instagram?: string, pinterest?: string, gis?: string, image_data?: Array<{ __typename?: 'ImageData', name?: string, title?: string, description?: string, path?: string }> } } };


export const ConstantDocument = `
    query Constant($slug: String!) {
  Constant(slug: $slug) {
    id
    name
    slug
    description
    value {
      phone
      address
      productSlugs: product_slugs
      vk: social_vk
      instagram: social_inst
      pinterest: social_pint
      gis: gis_link
      image_data {
        name
        title
        description
        path
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Constant: build.query<ConstantQuery, ConstantQueryVariables>({
      query: (variables) => ({ document: ConstantDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useConstantQuery, useLazyConstantQuery } = injectedRtkApi;

