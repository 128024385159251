const formatDays = (days: number): string => {
  const lastDigit = days % 10;
  const lastTwoDigits = days % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return `${days} дней`;
  }

  switch (lastDigit) {
    case 1:
      return `${days} день`;
    case 2:
    case 3:
    case 4:
      return `${days} дня`;
    default:
      return `${days} дней`;
  }
};
export const formatDateDifference = (dateTime: string): string => {
  const targetDate = new Date(dateTime);
  const currentDate = Date.now();

  const differenceInMillis = currentDate - targetDate.getTime();

  const differenceInDays = Math.floor(differenceInMillis / (1000 * 3600 * 24));

  return formatDays(differenceInDays);
};
