import { useMemo } from 'react';
import { CatalogItem } from 'entities/catalog';
import { LoadingHOC } from 'shared/ui';
import { Paths } from 'shared/config';
import { useCategoryQuery } from 'shared/api/categories';
import { generatePath } from 'react-router-dom';
import styles from './CategoriesList.module.scss';

interface CategoriesListProps {
  title: string;
  fromCatalog?: boolean;
}
export const CategoriesList = ({ title, fromCatalog }: CategoriesListProps) => {
  const query = useCategoryQuery();
  const categories = useMemo(() => query?.currentData?.Category, [query?.currentData]);
  return (
    <section className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.subtitle}>
        Нажмите, чтобы открыть
        <br />
        конструктор индивидуального дизайна
      </p>
      <div className={styles.wrapper}>
        <LoadingHOC isLoading={!categories} className={styles.loader}>
          {
            categories?.map((
              { id, name, slug, imagePath },
            ) => (
              <CatalogItem
                name={name}
                slug={fromCatalog ? slug : generatePath(Paths.Products, { path: slug })}
                imagePath={imagePath}
                key={id}
              />
            ))
          }
        </LoadingHOC>
      </div>
    </section>
  );
};
