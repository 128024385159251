/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { FormContainer } from 'entities/auth';
import { CodeInput, IconButton, ResendCode } from 'shared/ui';
import { MultistepPageProps } from 'shared/lib/multistep/types';
import { timestamp } from 'shared/lib';
import { ArrowBackIcon } from 'shared/ui/icons';
import { useCodeForm } from '../model';
import styles from './CodeForm.module.scss';

export const CodeForm = ({ pagination }: MultistepPageProps) => {
  const {
    onSubmit,
    setValue,
    contact,
    errors,
    clearErrors,
    disabled,
    setResendCode,
  } = useCodeForm(pagination);
  const isPhone = contact?.indexOf('+7') === 0;
  const [codeRequestTime, setCodeRequestTime] = useState(timestamp());
  const onCodeResend = (requestTime: number) => {
    setCodeRequestTime(requestTime);
  };
  const description = () => {
    if (isPhone) {
      return (
        <p>
          Введите последние 4 цифры номера входящего звонка на ваш номер
          <span>{contact}</span>
        </p>
      );
    }
    return (
      <p>
        Введите код или перейдите по ссылке, которые были отправлены на почту
        <span>{contact}</span>
      </p>
    );
  };
  return (
    <>
      <IconButton className={styles.stepBack} onClick={pagination.back}>
        <ArrowBackIcon />
      </IconButton>
      <FormContainer
        title="Подтверждение"
        id="reset-password"
        onSubmit={onSubmit}
        description={description()}
        className={styles.root}
      >
        <CodeInput
          setCode={(code) => {
            clearErrors();
            setValue('code', code);
            onSubmit();
          }}
          disabled={disabled}
          hasError={errors.code != null}
          help={errors.code?.message}
        />
        <ResendCode
          startTime={codeRequestTime}
          onResend={onCodeResend}
          setResendCode={setResendCode}
          isPhone={isPhone}
        />
      </FormContainer>
    </>
  );
};
