/* eslint-disable react/jsx-props-no-spreading */
import { FormContainer } from 'entities/auth';
import { ContactInput, Button } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { MultistepPageProps } from 'shared/lib/multistep/types';
import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';
import { Captcha } from 'shared/ui/form';
import styles from './ContactForm.module.scss';
import { useContactForm } from '../model';

interface FormData {
  contact: string;
  response: string;
}
export const ContactForm = ({ pagination }: MultistepPageProps) => {
  const captchaRef = useRef<Reaptcha>(null);
  const [captcha, setCaptcha] = useState(nanoid());
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    resetField,
    control,
  } = useForm<FormData>();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const onSubmit = useContactForm(
    { pagination, captchaRef, resetField, setCaptcha, setSubmitDisabled, setError },
  );
  return (
    <FormContainer
      title="Вход"
      id="login-by-code"
      onSubmit={handleSubmit(onSubmit)}
      description="Введите почту или телефон, мы отправим туда код подтверждения"
      className={styles.root}
    >
      <ContactInput
        id="email-phone"
        label="Почта или номер телефона"
        position="center"
        className={styles.contact}
        hasError={errors.contact != null}
        help={errors.contact ? errors.contact.message : undefined}
        {...register('contact', { required: errorMessage.required, validate: validateContact })}
      />
      <Captcha
        ref={captchaRef}
        key={captcha}
        name="response"
        control={control}
        size="invisible"
        onVerify={() => queueMicrotask(handleSubmit(onSubmit))}
      />
      <Button
        form="login-by-code"
        type="submit"
        className={styles.submit}
        disabled={submitDisabled}
      >
        Получить код
      </Button>
    </FormContainer>
  );
};
