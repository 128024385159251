import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useOrdersListQuery } from 'shared/api/orderHistory';

export const useGetOrderHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Number(searchParams.get('page') ?? '1');
  const query = useOrdersListQuery({ page: pageNumber, first: 5 });
  const history = useMemo(() => query.currentData?.OrdersList?.data, [query.currentData]);
  const paginatorInfo = useMemo(
    () => query.currentData?.OrdersList?.paginatorInfo,
    [query.currentData],
  );
  if (paginatorInfo?.lastPage !== undefined && pageNumber > paginatorInfo?.lastPage) {
    searchParams.set('page', String(paginatorInfo?.lastPage));
    setSearchParams(searchParams);
  }

  return {
    history,
    paginatorInfo,
  };
};
