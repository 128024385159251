/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode, useRef, useState } from 'react';
import { FormContainer } from 'entities/auth';
import { ContactInput, PasswordInput, Button, Captcha } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { useForm } from 'react-hook-form';
import { nanoid } from '@reduxjs/toolkit';
import Reaptcha from 'reaptcha';
import styles from './LoginByPasswordForm.module.scss';
import { useLoginByPasswordForm } from '../model';

interface LoginByPasswordFormProps {
  footer?: ReactNode,
}

interface FormData {
  contact: string;
  password: string;
  response: string;
}
export const LoginByPasswordForm = ({ footer }: LoginByPasswordFormProps) => {
  const captchaRef = useRef<Reaptcha>(null);
  const [captcha, setCaptcha] = useState(nanoid());
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    handleSubmit,
    register,
    setError,
    resetField,
    formState: { errors },
    control,
  } = useForm<FormData>();
  const onSubmit = useLoginByPasswordForm(
    { captchaRef, resetField, setCaptcha, setSubmitDisabled, setError },
  );
  return (
    <FormContainer
      title="Вход"
      id="login-by-password"
      onSubmit={handleSubmit(onSubmit)}
      className={styles.root}
      footer={footer}
    >
      <ContactInput
        id="email-phone"
        label="Почта или номер телефона"
        position="center"
        hasError={errors.contact != null}
        help={errors.contact ? errors.contact.message : undefined}
        className={styles.contact}
        {...register('contact', { required: errorMessage.required, validate: validateContact })}
      />
      <PasswordInput
        id="password"
        label="Пароль"
        position="center"
        hasError={errors.password != null}
        help={errors.password ? errorMessage[errors.password.type] : undefined}
        {...register('password', { required: true })}
      />
      <Button
        form="login-by-password"
        type="submit"
        className={styles.submit}
        disabled={submitDisabled}
      >
        Войти
      </Button>
      <Captcha
        ref={captchaRef}
        key={captcha}
        name="response"
        control={control}
        size="invisible"
        onVerify={() => queueMicrotask(handleSubmit(onSubmit))}
      />
    </FormContainer>
  );
};
