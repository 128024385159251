/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormControl, FormControlProps,
  FormGroup, FormLabel, FormText,
} from 'react-bootstrap';
import { usePatternFormat } from 'react-number-format';
import type { FormatInputValueFunction } from 'react-number-format/types/types';
import cn from 'classnames';
import { useHasValue } from 'shared/lib/hooks';
import styles from './Input.module.scss';

interface ContactInputProps extends FormControlProps {
  id: string;
  label: string;
  value?: string | number;
  defaultValue?: string | number;
  format?: string;
  help?: string;
  required?: boolean;
  hasStaticHelp?: boolean;
  hasError?: boolean;
  position?: string;
}

export const ContactInput = forwardRef<HTMLInputElement, ContactInputProps>((
  {
    label,
    required,
    id,
    // eslint-disable-next-line react/prop-types
    className,
    help,
    // eslint-disable-next-line react/prop-types
    onInput: outerOnInput,
    hasError = false,
    hasStaticHelp = false,
    defaultValue,
    position = 'left',
    ...rest
  },
  ref,
) => {
  const [inputValue, setInputValue] = useState(defaultValue?.toString() ?? '');
  const { hasValue, onInput } = useHasValue({
    ...rest,
    defaultValue,
    onInput: (e) => {
      // NOTE: it would be better to preserve user input from e.nativeEvent
      // In this case we must handle input types regarding carret position:
      // e.nativeEvent.inputType =
      //  (insertText, insertFromPaste, deleteContentBackward, deleteContentForward)
      const { value } = e.currentTarget;
      if (/^\+7?$/.test(value) && (e.nativeEvent as InputEvent).inputType === 'deleteContentBackward') {
        setInputValue('');
      } else {
        setInputValue(value);
      }
    },
  });
  useEffect(() => {
    setInputValue(defaultValue?.toString() ?? '');
  }, [defaultValue]);
  const { format: patternFormat } = usePatternFormat({ format: '+7 ### ###-##-##', mask: '' });
  const format: FormatInputValueFunction = useCallback((value) => (patternFormat ? patternFormat(
    value.length === 1 ? '_' : value.replace(/[+\d -]/, ''),
  ) : ''), [patternFormat]);
  const renderValue = useMemo(() => {
    const isPhoneMask = /^[+\d()\s-]+$/g.test(inputValue);

    let value = inputValue;
    if (/^\+7/.test(value)) {
      value = value.replaceAll(/[+()\s-]/g, '');
    }

    if (isPhoneMask) {
      const phoneValue = format(value);
      value = phoneValue.replaceAll(/[-\s]\D|(\D$)/g, '');
    }
    return value;
  }, [format, inputValue]);
  return (
    <FormGroup
      controlId={id}
      className={cn(
        styles.root,
        hasError ? styles.hasError : null,
        hasStaticHelp ? styles.rootWithStaticHelp : null,
        className,
      )}
    >
      <FormLabel className={position === 'center' ? styles.center : styles.left}>
        {label}
        {required ? <span className={styles.required}> *</span> : null}
      </FormLabel>
      <FormControl
        {...rest}
        value={renderValue}
        ref={ref}
        aria-describedby={help ? `${id}-help` : undefined}
        onInput={onInput}
        className={cn(hasValue && styles.hasValue)}
        maxLength={renderValue.indexOf('+7') === 0 ? 16 : undefined}
      />
      {help && (
        <FormText id={`${id}-help`}>{help}</FormText>
      )}
    </FormGroup>
  );
});
