import { Cartproduct, OrderStatus } from 'shared/api';

interface ProductFile {
  filePath: string;
  fileType?: {
    id: string;
    name: string;
  };
  id: string
}
export interface ProductsFiles {
  productFiles?: ProductFile[]
}

export interface HistoryType {
  id: string;
  status: OrderStatus;
  products?: ProductsFiles[],
  productsCount?: number;
  total: number;
  createdAt: string;
}

export interface OrderCardType {
  id?: string;
  status: OrderStatus;
  products?: Cartproduct[];
  total: number;
  comission: number;
  createdAt: string;
  address: string;
  fio: string;
  isAvailablePayment?: boolean;
  isAvailableTrack?: boolean;
  paymentLink?: string;
}

export enum OrderStatusValues {
  OrderApproval = 'Согласование заказа',
  AwaitingPayment = 'Ожидает оплаты',
  Paid = 'Оплачен',
  InAssembly = 'Производится',
  IsCreated = 'В сборке',
  InProgress = 'В пути',
  Delivered = 'Доставлен',
  Cancelled = 'Отменен',
}
