import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Paths } from 'shared/config';
import { usePersonalUpdateProfileMutation } from 'shared/api/profile';
import { useEffect, useMemo } from 'react';
import { useUser } from 'entities/user';

interface FormData {
  country: string;
  // TODO: Fix type
  city: any;
  address: any;
  flat: string;
  code: string;
}
export const useAddressChangeForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    resetField,
    watch,
    setValue,
  } = useForm<FormData>();
  const [updateProfile] = usePersonalUpdateProfileMutation();
  const navigate = useNavigate();
  const { user } = useUser();

  const cityValue = useMemo(() => ({
    value: user?.city,
    cityId: user?.cityId ?? user?.settlementId,
    city: user?.city ?? user?.settlement,
  }), [user]);
  const streetValue = useMemo(() => ({
    value: `${user?.street}, ${user?.house}`,
    street: user?.street,
    streetId: user?.streetId,
    house: user?.house,
    houseId: user?.houseId,
  }), [user]);

  useEffect(() => {
    if (!Object.values(cityValue).some((value: any) => value === null)) {
      setValue('city', cityValue);
    }
    if (!Object.values(streetValue).some((value: any) => value === null)) {
      setValue('address', streetValue);
    }
  }, [cityValue, setValue, streetValue]);
  useEffect(() => {
    setValue('country', 'Россия');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const {
      country,
      city,
      address,
      flat,
      code,
    } = data;
    const input = {
      country,
      city: city?.city,
      city_guid: city?.cityId,
      street: address?.street,
      street_guid: address?.streetId,
      house: address?.house,
      house_guid: address?.houseId,
      flat,
      postindex: code,
    };
    updateProfile({ input })
      .unwrap()
      .then(() => navigate(Paths.ProfileAddress));
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    resetField,
    watch,
    setValue,
    cityValue,
    streetValue,
  };
};
