export const AttributesIds = {
  LegStyle: '1',
  TableColor: '2',
  LegColor: '4',
  Material: '5',
  Size: '6',
  Form: '8',
  DresserBody: '9',
  DresserFront: '10',
  DresserChest: '11',
  DresserSize: '12',
};
