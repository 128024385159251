/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ChangePhoneWithoutPasswordStep1MutationVariables = Types.Exact<{
  input: Types.ChangePhoneWithoutPasswordStep1Input;
}>;


export type ChangePhoneWithoutPasswordStep1Mutation = { __typename?: 'Mutation', ChangePhoneWithoutPasswordStep1: string };


export const ChangePhoneWithoutPasswordStep1Document = `
    mutation ChangePhoneWithoutPasswordStep1($input: ChangePhoneWithoutPasswordStep1Input!) {
  ChangePhoneWithoutPasswordStep1(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ChangePhoneWithoutPasswordStep1: build.mutation<ChangePhoneWithoutPasswordStep1Mutation, ChangePhoneWithoutPasswordStep1MutationVariables>({
      query: (variables) => ({ document: ChangePhoneWithoutPasswordStep1Document, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangePhoneWithoutPasswordStep1Mutation } = injectedRtkApi;

