import { SubmitHandler, UseFormResetField, UseFormSetError } from 'react-hook-form';
import { useUser } from 'entities/user';
import { MultistepPagination, normalizePhone } from 'shared/lib';
import { Dispatch, RefObject, SetStateAction, useContext } from 'react';
import { useChangePhoneWithoutPasswordStep1Mutation } from 'shared/api/profile/phone';
import { useLazyIsUserExistsQuery } from 'shared/api/user';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';
import { ChangePhoneContext } from './ChangePhoneContext';

interface FormData {
  phone: string;
  response: string;
}

interface UsePhoneFormProps {
  pagination: MultistepPagination;
  captchaRef: RefObject<Reaptcha>;
  resetField: UseFormResetField<FormData>;
  setCaptcha: Dispatch<SetStateAction<string>>;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  setError: UseFormSetError<FormData>;
}
export const usePhoneForm = ({
  pagination,
  captchaRef,
  resetField,
  setCaptcha,
  setSubmitDisabled,
  setError,
}: UsePhoneFormProps) => {
  const { user } = useUser();
  const { setStep } = useContext(ChangePhoneContext);
  const [changePhoneWithoutPassword] = useChangePhoneWithoutPasswordStep1Mutation();
  const [isUserExists] = useLazyIsUserExistsQuery();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setSubmitDisabled(true);
    if (data.response == null) {
      captchaRef.current?.execute();
      return;
    }
    const input = { new_phone: normalizePhone(data.phone), response: data.response };
    const userInput = { phone: normalizePhone(data.phone) };
    if (!user?.phone && !user?.lastPasswordResetAt) {
      isUserExists({ input: userInput })
        .then(({ data: dataUser }: any) => {
          if (dataUser?.IsUserExists) {
            setError('phone', { type: '', message: 'Пользователь с таким телефоном уже существует' });
            setSubmitDisabled(false);
          } else {
            changePhoneWithoutPassword({ input })
              .unwrap()
              .then(() => {
                setStep(2, input);
                pagination.goTo(2);
              })
              .catch((e) => setError('phone', { type: '', message: e.message }))
              .finally(() => {
                setCaptcha(nanoid());
                resetField('response');
                setSubmitDisabled(false);
              });
          }
        });
    }
    if (user?.lastPasswordResetAt) {
      isUserExists({ input: userInput })
        .then(({ data: dataUser }: any) => {
          if (dataUser?.IsUserExists) {
            setError('phone', { type: '', message: 'Пользователь с таким телефоном уже существует' });
            setSubmitDisabled(false);
          } else {
            setStep(3, data);
            pagination.goTo(3);
          }
        });
    }
    if (!user?.lastPasswordResetAt && user?.phone) {
      isUserExists({ input: userInput })
        .then(({ data: dataUser }: any) => {
          if (dataUser?.IsUserExists) {
            setError('phone', { type: '', message: 'Пользователь с таким телефоном уже существует' });
            setSubmitDisabled(false);
          } else {
            changePhoneWithoutPassword({ input })
              .unwrap()
              .then(() => {
                setStep(1, data);
                pagination.goTo(1);
              })
              .catch((e) => setError('phone', { type: '', message: e.message }))
              .finally(() => {
                setCaptcha(nanoid());
                resetField('response');
                setSubmitDisabled(false);
              });
          }
        });
    }
  };
  return {
    onSubmit,
    defaultValue: user?.phone,
  };
};
