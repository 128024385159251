import { useContext } from 'react';
import { useFavouritesAddProductMutation } from 'shared/api/favourites';
import { NotificationContext, updateList } from 'entities/notification';
import { FavouritesAddProductMutation } from 'shared/api/favourites/addToFavourites.csr.generated';

export const useAddToFavorite = (productId?: string) => {
  const [addProduct, result] = useFavouritesAddProductMutation();
  const { setData } = useContext(NotificationContext);
  const addToFavorite = () => {
    if (productId) {
      updateList(
        addProduct({ id: productId })
          .unwrap(),
        { setData, convert: (response: FavouritesAddProductMutation) => response.FavouritesAddProduct, actionType: 'favorite' },
      );
    }
  };
  return {
    addToFavorite,
    inProcess: result.isLoading,
  };
};
