import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'entities/user';
import { MultistepPagination } from 'shared/lib';
import {
  useChangeEmailWithoutPasswordStep1Mutation,
  useConfirmOldEmailAndSendNewCodeMutation,
} from 'shared/api/profile/email';
import { ChangeEmailContext } from './ChangeEmailContext';

interface FormData {
  code: string;
}
export const useCodeForm = (pagination: MultistepPagination) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>();

  const { user } = useUser();
  const { setStep } = useContext(ChangeEmailContext);
  const { steps: { step1 } } = useContext(ChangeEmailContext);
  const [confirmOldEmail] = useConfirmOldEmailAndSendNewCodeMutation();
  const [changeEmailWithoutPassword] = useChangeEmailWithoutPasswordStep1Mutation();
  const setResendCode = () => {
    const input = { new_email: step1.email };
    changeEmailWithoutPassword({ input });
  };
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input = {
      new_email: step1.email,
      code: data.code,
    };
    confirmOldEmail({ input })
      .unwrap()
      .then(() => {
        setStep(2, data);
        pagination.goTo(2);
      })
      .catch((error) => {
        reset();
        setError('code', { type: '', message: error.message });
      });
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    setError,
    reset,
    clearErrors,
    email: user?.email || step1?.email,
    setResendCode,
  };
};
