import { EditInput } from 'shared/ui';
import { useUser } from 'entities/user';
import { useNavigate } from 'react-router';
import { Paths } from 'shared/config';
import { formatPhone } from 'shared/lib';
import styles from './ProfileInfo.module.scss';

export const ProfileInfo = () => {
  const user = useUser().user!;
  const navigate = useNavigate();
  const concatUserName = () => {
    if (user.name == null) {
      return '';
    }
    if (user.middlename == null) {
      return `${user.surname} ${user.name}`;
    }
    return `${user.surname} ${user.name} ${user.middlename}`;
  };
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Основная информация</h2>
      <div className={styles.wrapper}>
        <EditInput label="ФИО" value={concatUserName()} onEdit={() => navigate(Paths.ProfileName)} />
        <EditInput label="Телефон" value={formatPhone(user.phone)} onEdit={() => navigate(Paths.ProfilePhone)} />
        <EditInput label="Почта" value={user.email} onEdit={() => navigate(Paths.ProfileEmail)} />
        {
          user.isDesigner && (
            <div className={styles.partner}>
              <span>Вы являетесь партнером </span>
              <p>Вам начисляется комиссия 10% от каждого заказа</p>
            </div>
          )
        }
      </div>
    </div>
  );
};
