import { Container } from 'react-bootstrap';
import { delivery } from '../__mocks__';
import styles from './DeliveryPage.module.scss';

export const DeliveryPage = () => (
  <div className={styles.root}>
    <Container>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{delivery.title}</h1>
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: delivery.content }} />
      </div>
    </Container>
  </div>
);
