import { TAG_USER } from '../user';
import { api as updateProfileApi } from './updateProfile.csr.generated';

export { usePersonalUpdateProfileMutation } from './updateProfile.csr.generated';
export { useChangePasswordMutation } from './changePassword.csr.generated';

updateProfileApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: { PersonalUpdateProfile: { invalidatesTags: [TAG_USER] } },
});
