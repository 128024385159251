import { Container } from 'react-bootstrap';
import { offer } from '../__mocks__';
import styles from './PolicyPage.module.scss';

export const OfferPage = () => (
  <div className={styles.root}>
    <Container>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{offer.title}</h1>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: offer.content }}
        />
      </div>
    </Container>
  </div>
);
