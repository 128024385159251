/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ConfirmNewEmailMutationVariables = Types.Exact<{
  input: Types.ConfirmNewEmailInput;
}>;


export type ConfirmNewEmailMutation = { __typename?: 'Mutation', ConfirmNewEmail: string };


export const ConfirmNewEmailDocument = `
    mutation ConfirmNewEmail($input: ConfirmNewEmailInput!) {
  ConfirmNewEmail(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ConfirmNewEmail: build.mutation<ConfirmNewEmailMutation, ConfirmNewEmailMutationVariables>({
      query: (variables) => ({ document: ConfirmNewEmailDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useConfirmNewEmailMutation } = injectedRtkApi;

