import { TAG_USER } from 'shared/api/user';
import { api as confirmChangePhoneWithPasswordApi } from './ConfirmChangePhoneWithPassword.csr.generated';
import { api as confirmNewPhoneApi } from './ConfirmNewPhone.csr.generated';

export { useChangePhoneWithoutPasswordStep1Mutation } from './ChangePhoneWithoutPasswordStep1.csr.generated';
export { useChangePhoneWithPasswordMutation } from './ChangePhoneWithPassword.csr.generated';
export { useConfirmChangePhoneWithPasswordMutation } from './ConfirmChangePhoneWithPassword.csr.generated';
export { useConfirmNewPhoneMutation } from './ConfirmNewPhone.csr.generated';
export { useConfirmOldPhoneAndSendNewCodeMutation } from './ConfirmOldPhoneAndSendNewCode.csr.generated';

confirmChangePhoneWithPasswordApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    ConfirmChangePhoneWithPassword: {
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(confirmChangePhoneWithPasswordApi.util.invalidateTags([TAG_USER as never]));
          }
        } catch { /* empty */ }
      },
    },
  },
});

confirmNewPhoneApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    ConfirmNewPhone: {
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(confirmNewPhoneApi.util.invalidateTags([TAG_USER as never]));
          }
        } catch { /* empty */ }
      },
    },
  },
});
