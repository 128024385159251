import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { MultistepPagination } from 'shared/lib/multistep/types';
import { PasswordRecoveryStep1Input, PasswordRecoveryStep2Input } from 'shared/api';
import { normalizePhone, regex } from 'shared/lib';
import { useResetPasswordStep1Mutation, useResetPasswordStep2Mutation } from 'shared/api/reset-password';
import { ResetPasswordContext } from './ResetPasswordContext';

interface FormData {
  code: string;
}
export function useCodeForm(pagination: MultistepPagination) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm<FormData>();
  const { steps: { step1 }, setStep } = useContext(ResetPasswordContext);
  const [disabled, setDisabled] = useState(false);
  const [resetPasswordStep1] = useResetPasswordStep1Mutation();
  const [resetPasswordStep2] = useResetPasswordStep2Mutation();
  const setResendCode = (isSms: boolean) => {
    const input: PasswordRecoveryStep1Input = { is_sms: isSms };
    if (regex.email.test(step1!.contact)) {
      input.email = step1!.contact;
    } else {
      input.phone = normalizePhone(step1!.contact);
    }
    resetPasswordStep1({ input });
  };
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input: PasswordRecoveryStep2Input = { ...data };
    if (regex.email.test(step1!.contact)) {
      input.email = step1!.contact;
    } else {
      input.phone = normalizePhone(step1!.contact);
    }
    resetPasswordStep2({ input })
      .unwrap()
      .then(() => {
        setStep(2, data);
        pagination.next();
      })
      .catch((error) => {
        // TODO: need to remove hard code
        if (error.message === 'Большое количество попыток ввода короткого кода, попробуйте через 10 минут.') {
          setDisabled(!disabled);
          setTimeout(() => setDisabled(false), 600000);
        }
        // TODO: fix reset
        reset();
        setError('code', { type: 'custom', message: error.message });
      });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    clearErrors,
    contact: step1?.contact,
    setResendCode,
    disabled,
  };
}
