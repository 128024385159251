import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useState } from 'react';
import { useDispatch } from 'shared/model';
import { Paths } from 'shared/config';
import { normalizePhone, regex } from 'shared/lib';
import type { LoginByCodeStep2Input, LoginByCodeStep1Input } from 'shared/api';
import { setAuthData, castAuthData } from 'entities/user';
import { useLoginStep2Mutation, useLoginStep1Mutation } from 'shared/api/login-by-code';
import { useLazyIsUserExistsQuery } from 'shared/api/user';
import { IsUserExistsInput } from 'shared/api';
import { useNavigate } from 'react-router';
import { LoginByCodeContext } from './LoginByCodeContext';

interface FormData {
  code: string;
  designer: boolean;
}
export function useCodeForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>();
  const { steps: { step1 } } = useContext(LoginByCodeContext);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [loginStep2] = useLoginStep2Mutation();
  const [loginStep1] = useLoginStep1Mutation();
  const [isUserExists] = useLazyIsUserExistsQuery();
  const navigate = useNavigate();
  const setResendCode = (isSms: boolean) => {
    const input: LoginByCodeStep1Input = { is_sms: isSms };
    if (regex.email.test(step1!.contact)) {
      input.email = step1!.contact;
    } else {
      input.phone = normalizePhone(step1!.contact);
    }
    loginStep1({ input });
  };
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input: LoginByCodeStep2Input = {
      is_designer: data.designer,
      code: data.code,
    };
    const userInput: IsUserExistsInput = {};
    if (regex.email.test(step1!.contact)) {
      input.email = step1!.contact;
      userInput.email = step1!.contact;
    } else {
      input.phone = normalizePhone(step1!.contact);
      userInput.phone = normalizePhone(step1!.contact);
    }
    let hasUser = false;
    isUserExists({ input: userInput })
      .then(({ data: dataUser }: any) => {
        if (dataUser?.IsUserExists) {
          hasUser = true;
        }
      });
    loginStep2({ input })
      .unwrap()
      .then((res) => {
        const authData = castAuthData(res.LoginByCodeStep2);
        if (authData == null) {
          throw new Error();
        }
        dispatch(setAuthData(authData));
        if (hasUser) {
          navigate(Paths.Root);
        } else {
          setPopupVisible(true);
        }
      })
      .catch((error) => {
        // TODO: need to remove hard code
        if (error.message === 'Большое количество попыток ввода короткого кода, попробуйте через 10 минут.') {
          setDisabled(!disabled);
          setTimeout(() => setDisabled(false), 600000);
        }
        reset();
        setError('code', { type: 'custom', message: error.message });
      });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    isPopupVisible,
    setPopupVisible,
    clearErrors,
    contact: step1?.contact,
    disabled,
    setResendCode,
  };
}
