import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Paths } from 'shared/config';
import { useLogoutMutation } from 'shared/api/user';
import styles from './ProfileMenu.module.scss';

const profileMenuItems = [
  {
    id: '1',
    link: Paths.Profile,
    text: 'Основная информация',
  },
  {
    id: '2',
    link: Paths.Favourites,
    text: 'Избранное',
  },
  {
    id: '3',
    link: Paths.ProfileAddress,
    text: 'Адрес',
  },
  {
    id: '4',
    link: Paths.ProfilePassword,
    text: 'Пароль',
  },
  {
    id: '5',
    link: Paths.ProfileHistory,
    text: 'История заказов',
  },
];
export const ProfileMenu = () => {
  const location = useLocation();
  const slugs = location.pathname.split('/').slice(1, 3);
  const link = `/${slugs.join('/')}`;
  const [logout] = useLogoutMutation();
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Личный кабинет</h1>
      <nav className={styles.navigation}>
        {
          profileMenuItems.map(
            (item) => (
              <Link
                to={item.link}
                key={item.id}
                className={cn(
                  styles.link,
                  link === item.link && styles.active,
                )}
              >
                {item.text}
              </Link>
            ),
          )
        }
      </nav>
      <button
        className={styles.logout}
        type="button"
        onClick={() => logout()}
      >
        Выйти
      </button>
    </div>
  );
};
