import { SubmitHandler, UseFormResetField } from 'react-hook-form';
import { normalizePhone } from 'shared/lib';
import { useLeadsCreateLeadMutation } from 'shared/api/forms';
import { Dispatch, RefObject, SetStateAction, useState } from 'react';
import Reaptcha from 'reaptcha';
import { nanoid } from '@reduxjs/toolkit';

interface FormData {
  phone: string;
  response: string;
}

interface UseDesignerForm {
  captchaRef: RefObject<Reaptcha>;
  resetField: UseFormResetField<FormData>;
  setCaptcha: Dispatch<SetStateAction<string>>;
  setSubmitDisabled: Dispatch<SetStateAction<boolean>>;
  setPopupVisible: Dispatch<SetStateAction<boolean>>;
}

export const useDesignerForm = ({
  captchaRef,
  resetField,
  setCaptcha,
  setSubmitDisabled,
  setPopupVisible,
}: UseDesignerForm) => {
  const [createLead] = useLeadsCreateLeadMutation();
  const [isSuccessPopupVisible, setSuccessIsPopupVisible] = useState(false);
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setSubmitDisabled(true);
    if (data.response == null) {
      captchaRef.current?.execute();
      return;
    }
    const input = {
      type: 'Дизайнер интерьера',
      phone: normalizePhone(data.phone),
      response: data.response,
    };
    createLead({ input })
      .unwrap()
      .then(() => {
        setPopupVisible(false);
        setSuccessIsPopupVisible(true);
        resetField('phone');
      })
      .finally(() => {
        setCaptcha(nanoid());
        resetField('response');
        setSubmitDisabled(false);
      });
  };
  return {
    onSubmit,
    isSuccessPopupVisible,
    setSuccessIsPopupVisible,
  };
};
