import { CartIcon } from 'shared/ui';
import cn from 'classnames';
import styles from './CartLink.module.scss';

interface CartLinkProps {
  className?: string;
  count?: number;
  isFulfilled?: boolean;
}

export const CartLink = ({ className, count = 0, isFulfilled = true }: CartLinkProps) => (
  <div className={styles.root}>
    {(count > 0 && isFulfilled) && <div className={styles.count}>{count}</div>}
    <CartIcon className={cn(styles.icon, className)} />
  </div>
);
