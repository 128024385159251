/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type FavouritesProductToCartMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type FavouritesProductToCartMutation = { __typename?: 'Mutation', FavouritesProductToCart: { __typename?: 'CartFront', id?: string } };


export const FavouritesProductToCartDocument = `
    mutation FavouritesProductToCart($id: ID!) {
  FavouritesProductToCart(id: $id) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    FavouritesProductToCart: build.mutation<FavouritesProductToCartMutation, FavouritesProductToCartMutationVariables>({
      query: (variables) => ({ document: FavouritesProductToCartDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFavouritesProductToCartMutation } = injectedRtkApi;

