import { Button } from 'shared/ui';
import { useState } from 'react';
import styles from './Designer.module.scss';
import { DesignerForm } from './DesignerForm';

export const Designer = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  return (
    <section className={styles.root}>
      <h2 className={styles.title}>Дизайнер интерьера?</h2>
      <p className={styles.subtitle}>Приглашаем к сотрудничеству</p>
      <Button className={styles.btn} onClick={() => setPopupVisible(true)}>Оставить заявку</Button>
      <DesignerForm setPopupVisible={setPopupVisible} isPopupVisible={isPopupVisible} />
    </section>
  );
};
